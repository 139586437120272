import { appConfigAtom } from "@/atoms";
import { ParticipantListDisplay } from "@/common/ParticipantListDisplay";
import { Alert } from "@/components/ui/alert";
import { buttonVariants } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { UserAccount } from "@/generatedApi/eventManagerApi";
import { getJstDateFromUnixtime } from "@/utils";
import { useAtomValue } from "jotai";
import { ChevronRight, Edit2 } from "lucide-react";
import { Link } from "react-router-dom";

type ParticipantListCardProps = {
  userId: string;
  userAccount?: UserAccount;
};
export const ParticipantListCard = ({
  userId,
  userAccount,
}: ParticipantListCardProps) => {
  const participantList = userAccount?.participant_list;

  const appConfig = useAtomValue(appConfigAtom);
  if (!appConfig) return <>読み込み中...</>;
  return (
    <>
      {participantList ? (
        <Card>
          <CardHeader>
            <div className="mb-4 flex items-center justify-between">
              <div className="space-y-2">
                <CardTitle className="">参加者</CardTitle>
                <CardDescription>
                  {appConfig.flags.is_user_account_updatable ? (
                    <>
                      {getJstDateFromUnixtime(
                        Date.parse(appConfig.params.end_update_user_account)
                      )}
                      まで変更可能です。
                    </>
                  ) : (
                    <>変更受付期間外です。</>
                  )}
                </CardDescription>
              </div>
              {appConfig.flags.is_user_account_updatable && (
                <Link
                  to={`/users/${userId}/edit/participant_list`}
                  className={buttonVariants({
                    variant: "secondary",
                    size: "sm",
                  })}
                >
                  <Edit2 className="mr-2 h-4 w-4" />
                  編集
                </Link>
              )}
            </div>
          </CardHeader>
          <CardContent>
            <ParticipantListDisplay participantList={participantList} />
          </CardContent>
        </Card>
      ) : (
        <Card>
          <CardHeader>
            <div className="mb-4 flex items-center justify-between">
              <CardTitle className="flex items-center gap-1">参加者</CardTitle>
            </div>
          </CardHeader>
          <CardContent>
            <p>参加者が登録されていません。</p>
          </CardContent>
          <CardFooter>
            {appConfig.flags.is_user_account_updatable ? (
              <Link
                to={`/users/${userId}/edit/participant_list`}
                className={buttonVariants({})}
              >
                参加者情報を登録
                <ChevronRight className="ml-1 h-4 w-4" />
              </Link>
            ) : (
              <Alert variant="destructive">登録受付期間外です。</Alert>
            )}
          </CardFooter>
        </Card>
      )}
    </>
  );
};
