/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EventSession } from '../models/EventSession';
import type { EventSessionCreate } from '../models/EventSessionCreate';
import type { ReservationRequest } from '../models/ReservationRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EventSessionsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Event Session List
     * @returns EventSession Successful Response
     * @throws ApiError
     */
    public getEventSessionList({
        userId,
    }: {
        userId?: (string | null),
    }): CancelablePromise<Array<EventSession>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/event_sessions/',
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Event Session
     * @returns EventSession Successful Response
     * @throws ApiError
     */
    public createEventSession({
        requestBody,
        userId,
    }: {
        requestBody: EventSessionCreate,
        userId?: (string | null),
    }): CancelablePromise<EventSession> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/event_sessions/',
            query: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Session
     * @returns EventSession Successful Response
     * @throws ApiError
     */
    public getEventSession({
        eventSessionId,
        userId,
    }: {
        eventSessionId: string,
        userId?: (string | null),
    }): CancelablePromise<EventSession> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/event_sessions/{event_session_id}',
            path: {
                'event_session_id': eventSessionId,
            },
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Event Session
     * @returns EventSession Successful Response
     * @throws ApiError
     */
    public updateEventSession({
        eventSessionId,
        requestBody,
        userId,
    }: {
        eventSessionId: string,
        requestBody: EventSessionCreate,
        userId?: (string | null),
    }): CancelablePromise<EventSession> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/event_sessions/{event_session_id}',
            path: {
                'event_session_id': eventSessionId,
            },
            query: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Event Session
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteEventSession({
        eventSessionId,
        userId,
    }: {
        eventSessionId: string,
        userId?: (string | null),
    }): CancelablePromise<(boolean | null)> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/event_sessions/{event_session_id}',
            path: {
                'event_session_id': eventSessionId,
            },
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Session Reservation Request List
     * @returns ReservationRequest Successful Response
     * @throws ApiError
     */
    public getEventSessionReservationRequestList({
        eventSessionId,
        userId,
    }: {
        eventSessionId: string,
        userId?: (string | null),
    }): CancelablePromise<Array<ReservationRequest>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/event_sessions/{event_session_id}/reservation_requests',
            path: {
                'event_session_id': eventSessionId,
            },
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Session Reservation Request Count
     * @returns number Successful Response
     * @throws ApiError
     */
    public getEventSessionReservationRequestCount({
        eventSessionId,
        userId,
    }: {
        eventSessionId: string,
        userId?: (string | null),
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/event_sessions/{event_session_id}/reservation_requests/count',
            path: {
                'event_session_id': eventSessionId,
            },
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Session Reservation Request Participant Count
     * @returns number Successful Response
     * @throws ApiError
     */
    public getEventSessionReservationRequestParticipantCount({
        eventSessionId,
        userId,
    }: {
        eventSessionId: string,
        userId?: (string | null),
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/event_sessions/{event_session_id}/reservation_requests/participant_count',
            path: {
                'event_session_id': eventSessionId,
            },
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Session Reservation Request Capacity Filled Rate
     * @returns number Successful Response
     * @throws ApiError
     */
    public getEventSessionReservationRequestCapacityFilledRate({
        eventSessionId,
        userId,
    }: {
        eventSessionId: string,
        userId?: (string | null),
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/event_sessions/{event_session_id}/reservation_requests/capacity_filled_rate',
            path: {
                'event_session_id': eventSessionId,
            },
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Session Confirmed Reservation Count
     * @returns number Successful Response
     * @throws ApiError
     */
    public getEventSessionConfirmedReservationCount({
        eventSessionId,
        userId,
    }: {
        eventSessionId: string,
        userId?: (string | null),
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/event_sessions/{event_session_id}/confirmed_reservations/count',
            path: {
                'event_session_id': eventSessionId,
            },
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Session Confirmed Reservation Participant Count
     * @returns number Successful Response
     * @throws ApiError
     */
    public getEventSessionConfirmedReservationParticipantCount({
        eventSessionId,
        userId,
    }: {
        eventSessionId: string,
        userId?: (string | null),
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/event_sessions/{event_session_id}/confirmed_reservations/participant_count',
            path: {
                'event_session_id': eventSessionId,
            },
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Session Confirmed Reservation Capacity Filled Rate
     * @returns number Successful Response
     * @throws ApiError
     */
    public getEventSessionConfirmedReservationCapacityFilledRate({
        eventSessionId,
        userId,
    }: {
        eventSessionId: string,
        userId?: (string | null),
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/event_sessions/{event_session_id}/confirmed_reservations/capacity_filled_rate',
            path: {
                'event_session_id': eventSessionId,
            },
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Session Check In Count
     * @returns number Successful Response
     * @throws ApiError
     */
    public getEventSessionCheckInCount({
        eventSessionId,
        userId,
    }: {
        eventSessionId: string,
        userId?: (string | null),
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/event_sessions/{event_session_id}/check_ins/count',
            path: {
                'event_session_id': eventSessionId,
            },
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Session Check In Participant Count
     * @returns number Successful Response
     * @throws ApiError
     */
    public getEventSessionCheckInParticipantCount({
        eventSessionId,
        userId,
    }: {
        eventSessionId: string,
        userId?: (string | null),
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/event_sessions/{event_session_id}/check_ins/participant_count',
            path: {
                'event_session_id': eventSessionId,
            },
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
