import {
  confirmResetPassword,
  confirmSignIn,
  confirmSignUp,
  resendSignUpCode,
  resetPassword,
  ResetPasswordOutput,
  signIn,
  SignInOutput,
  signUp,
  SignUpOutput,
} from "aws-amplify/auth";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

export interface ConfirmSignUpInput {
  email: string;
  confirmationCode: string;
}

export interface ResendSignUpCodeInput {
  email: string;
}

export interface SignUpInput {
  email: string;
  password: string;
}

export interface SignInInput {
  email: string;
  password: string;
}

export interface ResetPasswordInput {
  email: string;
}

export interface ConfirmResetPasswordInput {
  email: string;
  newPassword: string;
  confirmationCode: string;
}

export interface ConfirmSignInInput {
  newPassword: string;
}

///

type SetRootError = (message: string) => void;

type AuthInput = {
  email?: string;
  password?: string;
  confirmationCode?: string;
};

export type AUTH_STEP =
  | "SIGN_IN"
  | "SIGN_UP"
  | "CONFIRM_SIGN_UP"
  | "RESET_PASSWORD"
  | "CONFIRM_RESET_PASSWORD"
  | "CONFIRM_SIGN_IN";

///

type useConfirmSignUpProps = {
  setRootError: SetRootError;
  setStep: React.Dispatch<React.SetStateAction<AUTH_STEP>>;
};
export const useConfirmSignUp = ({
  setRootError,
  setStep,
}: useConfirmSignUpProps) => {
  const { handleSignUpStep } = useHandleSignUpStep({ setRootError, setStep });
  const { sender: handleSignIn } = useSignIn({ setRootError, setStep });
  const sender = async (data: SignUpInput & ConfirmSignUpInput) => {
    try {
      const {
        nextStep: { signUpStep },
      } = await confirmSignUp({
        username: data.email,
        confirmationCode: data.confirmationCode,
      });
      handleSignUpStep(signUpStep, data);
    } catch (e) {
      console.info(e);
      // @ts-expect-error todo resolve
      switch (e.name) {
        case "CodeMismatchException":
          // 無効なコードが入力された場合に起こる。
          setRootError(
            "無効なコードが入力されました。もう一度確認してください。"
          );
          break;
        case "LimitExceededException":
          // コードを間違え続けた場合に起こる。
          setRootError(
            "間違ったコードが一定回数入力されたので、ロックされました。しばらく経ってから再度お試しください。"
          );
          break;
        case "ExpiredCodeException":
          // コードが期限切れ（24時間をオーバー）した場合に起こる。
          // 注) username が存在しない・無効化されている場合にも起こる。
          setRootError("コードが期限切れか無効です。");
          break;
        case "NotAuthorizedException":
          // 既にステータスが CONFIRMED になっている場合に起こる。
          setRootError("既に登録が完了しています。");
          handleSignIn({ email: data.email, password: data.password });
          break;
        case "CodeDeliveryFailureException":
          // 検証コードの送信に失敗した場合に起こる。
          setRootError(
            "検証コードの送信に失敗しました。もう一度お試しください。"
          );
          break;
        case "EmptyConfirmSignUpUsername":
          // username が空の場合に起こる。
          setRootError("メールアドレスが空です。");
          break;
        default:
          setRootError(
            "不明なエラーが発生しました。管理者にお問い合わせください。"
          );
          break;
      }
    }
  };
  return { sender };
};

///

type useResendSignUpCodeProps = {
  setRootError: SetRootError;
};
export const useResendSignUpCode = ({
  setRootError,
}: useResendSignUpCodeProps) => {
  const sender = async (data: ResendSignUpCodeInput) => {
    try {
      await resendSignUpCode({
        username: data.email,
      });
      toast.success("メールアドレスに確認コードを再送しました。");
    } catch (e) {
      console.info(e);
      // @ts-expect-error todo resolve
      switch (e.name) {
        case "UsernameExistsException":
          setRootError("このメールアドレスは既に登録されています。");
          break;
        case "InvalidParameterException":
          setRootError("メールアドレスの形式が正しくありません。");
          break;
        case "LimitExceededException":
          // コードを間違え続けた場合に起こる。
          setRootError(
            "しばらく経ってから再度お試しください。"
          );
          break;
        default:
          setRootError(
            "不明なエラーが発生しました。管理者にお問い合わせください。"
          );
          break;
      }
    }
  };
  return { sender };
};

///

type useHandleSignUpStepProps = {
  setRootError: SetRootError;
  setStep: React.Dispatch<React.SetStateAction<AUTH_STEP>>;
};

const useHandleSignUpStep = ({
  setRootError,
  setStep,
}: useHandleSignUpStepProps) => {
  const { sender: handleSignIn } = useSignIn({ setRootError, setStep });
  const handleSignUpStep = async (
    signUpStep: SignUpOutput["nextStep"]["signUpStep"],
    data: SignInInput
  ) => {
    switch (signUpStep) {
      case "DONE":
        toast.success("登録が完了しました。");
        await handleSignIn(data);
        break;
      case "COMPLETE_AUTO_SIGN_IN":
        toast.error(
          "不明なエラーが発生しました。管理者にお問い合わせください。"
        );
        break;
      case "CONFIRM_SIGN_UP":
        toast.success("メールアドレスに確認コードを送信しました。");
        setStep("CONFIRM_SIGN_UP");
        break;
    }
  };
  return { handleSignUpStep };
};

///

type useSignUpProps = {
  setRootError: SetRootError;
  setStep: React.Dispatch<React.SetStateAction<AUTH_STEP>>;
};
export const useSignUp = ({ setRootError, setStep }: useSignUpProps) => {
  const { sender: handleSignIn } = useSignIn({ setRootError, setStep });
  const { handleSignUpStep } = useHandleSignUpStep({
    setRootError,
    setStep,
  });

  const sender = useCallback(
    async (data: AuthInput & SignUpInput) => {
      try {
        const {
          nextStep: { signUpStep },
        } = await signUp({
          username: data.email,
          password: data.password,
        });
        await handleSignUpStep(signUpStep, data);
      } catch (e) {
        console.info(e);
        // @ts-expect-error todo resolve
        switch (e.name) {
          case "UsernameExistsException":
            setRootError("このメールアドレスは既に登録されています。");
            setStep("SIGN_IN");
            handleSignIn({ email: data.email, password: data.password });
            break;
          case "InvalidPasswordException":
            setRootError(
              "パスワードには英数字を含む8文字以上の文字列を入力してください。"
            );
            break;
          case "InvalidParameterException":
            setRootError(
              "メールアドレスの形式かパスワードの形式が正しくありません。"
            );
            break;
          default:
            setRootError(
              "不明なエラーが発生しました。管理者にお問い合わせください。"
            );
            break;
        }
      }
    },
    [setRootError, handleSignUpStep, handleSignIn, setStep]
  );

  return { sender };
};

///

type useHandleSignInStepProps = {
  setRootError: SetRootError;
  setStep: React.Dispatch<React.SetStateAction<AUTH_STEP>>;
};
const useHandleSignInStep = ({
  setRootError,
  setStep,
}: useHandleSignInStepProps) => {
  const navigate = useNavigate();
  const handleSignInStep = async (
    signInStep: SignInOutput["nextStep"]["signInStep"]
  ) => {
    switch (signInStep) {
      case "DONE":
        toast.success("ログインしました。");
        navigate("/");
        window.location.reload();
        break;
      case "CONFIRM_SIGN_UP":
        toast.success(
          "メールアドレスが未確認です。確認コードを入力してください。"
        );
        setStep("CONFIRM_SIGN_UP");
        break;
      case "RESET_PASSWORD":
        toast.success("パスワードをリセットしました。");
        setStep("RESET_PASSWORD");
        break;
      case "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED":
        toast.success("パスワードの変更が必要です。");
        setStep("CONFIRM_SIGN_IN");
        break;
      case "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE":
      case "CONFIRM_SIGN_IN_WITH_SMS_CODE":
      case "CONFIRM_SIGN_IN_WITH_TOTP_CODE":
      case "CONTINUE_SIGN_IN_WITH_MFA_SELECTION":
      case "CONTINUE_SIGN_IN_WITH_TOTP_SETUP":
        setRootError(
          "不明なエラーが発生しました。管理者にお問い合わせください。"
        );
        break;
    }
  };
  return { handleSignInStep };
};

///

type useSignInProps = {
  setRootError: SetRootError;
  setStep: React.Dispatch<React.SetStateAction<AUTH_STEP>>;
};
export const useSignIn = ({ setRootError, setStep }: useSignInProps) => {
  const navigate = useNavigate();
  const { handleSignInStep } = useHandleSignInStep({ setRootError, setStep });
  const sender = async (data: SignInInput) => {
    try {
      const {
        nextStep: { signInStep },
      } = await signIn({
        username: data.email,
        password: data.password,
      });
      await handleSignInStep(signInStep);
    } catch (e) {
      console.info(e);
      // @ts-expect-error todo resolve
      switch (e.name) {
        case "UserNotFoundException":
          setRootError("メールアドレスかパスワードが間違っています。");
          break;
        case "NotAuthorizedException":
          setRootError("メールアドレスかパスワードが間違っています。");
          break;
        case "UserNotConfirmedException":
          toast.error(
            "メールアドレスが未確認です。確認コードを入力してください。"
          );
          break;
        case "UserAlreadyAuthenticatedException":
          toast.error("既にログインしています。");
          navigate("/");
          window.location.reload();
          break;
        default:
          setRootError(
            "不明なエラーが発生しました。管理者にお問い合わせください。"
          );
          break;
      }
    }
  };
  return { sender };
};

///
type useHandleResetPasswordStepProps = {
  setStep: React.Dispatch<React.SetStateAction<AUTH_STEP>>;
  setRootError: SetRootError;
};
const useHandleResetPasswordStep = ({
  setStep,
  setRootError,
}: useHandleResetPasswordStepProps) => {
  const { sender: handleSignIn } = useSignIn({ setRootError, setStep });
  const handleResetPasswordStep = async (
    resetPasswordStep: ResetPasswordOutput["nextStep"]["resetPasswordStep"],
    data?: SignInInput
  ) => {
    switch (resetPasswordStep) {
      case "CONFIRM_RESET_PASSWORD_WITH_CODE":
        setStep("CONFIRM_RESET_PASSWORD");
        toast.success("メールアドレスに確認コードを送信しました。");
        break;
      case "DONE":
        toast.success("パスワードをリセットしました。");
        setStep("SIGN_IN");
        data && handleSignIn(data);
        break;
    }
  };
  return { handleResetPasswordStep };
};

///

type useResetPasswordProps = {
  setStep: React.Dispatch<React.SetStateAction<AUTH_STEP>>;
  setRootError: SetRootError;
};
export const useResetPassword = ({
  setStep,
  setRootError,
}: useResetPasswordProps) => {
  const { handleResetPasswordStep } = useHandleResetPasswordStep({
    setStep,
    setRootError,
  });
  const sender = async (data: AuthInput & ResetPasswordInput) => {
    try {
      const {
        nextStep: { resetPasswordStep },
      } = await resetPassword({
        username: data.email,
      });
      handleResetPasswordStep(resetPasswordStep);
    } catch (e) {
      console.info(e);
      // @ts-expect-error todo resolve
      switch (e.name) {
        case "UserNotFoundException":
          setRootError("このメールアドレスは登録されていません。");
          break;
        case "InvalidParameterException":
          setRootError("メールアドレスの形式が正しくありません。");
          break;
        default:
          setRootError(
            "不明なエラーが発生しました。管理者にお問い合わせください。"
          );
          break;
      }
    }
  };
  return { sender };
};

///

type useConfirmResetPasswordProps = {
  setStep: React.Dispatch<React.SetStateAction<AUTH_STEP>>;
  setRootError: SetRootError;
};
export const useConfirmResetPassword = ({
  setStep,
  setRootError,
}: useConfirmResetPasswordProps) => {
  const { sender: handleSignIn } = useSignIn({ setRootError, setStep });
  const sender = async (
    data: ConfirmResetPasswordInput & ConfirmSignUpInput
  ) => {
    try {
      await confirmResetPassword({
        username: data.email,
        newPassword: data.newPassword,
        confirmationCode: data.confirmationCode,
      });
      setStep("SIGN_IN");
      handleSignIn({ email: data.email, password: data.newPassword });
    } catch (e) {
      console.info(e);
      // @ts-expect-error todo resolve
      switch (e.name) {
        case "CodeMismatchException":
          setRootError(
            "無効なコードが入力されました。もう一度確認してください。"
          );
          break;
        case "UserNotFoundException":
          setRootError("このメールアドレスは登録されていません。");
          break;
        case "InvalidParameterException":
          setRootError("メールアドレスの形式が正しくありません。");
          break;
        case "InvalidPasswordException":
          setRootError("パスワードの形式が正しくありません。");
          break;
        case "LimitExceededException":
          // コードを間違え続けた場合に起こる。
          setRootError(
            "間違ったコードが一定回数入力されたので、ロックされました。しばらく経ってから再度お試しください。"
          );
          break;
        default:
          setRootError(
            "不明なエラーが発生しました。管理者にお問い合わせください。"
          );
          break;
      }
    }
  };
  return { sender };
};

///

type useConfirmSignInProps = {
  setStep: React.Dispatch<React.SetStateAction<AUTH_STEP>>;
  setRootError: SetRootError;
};
export const useConfirmSignIn = ({
  setStep,
  setRootError,
}: useConfirmSignInProps) => {
  const { handleSignInStep } = useHandleSignInStep({ setRootError, setStep });

  const navigate = useNavigate();

  const sender = async (data: ConfirmSignInInput) => {
    try {
      const {
        nextStep: { signInStep },
      } = await confirmSignIn({
        challengeResponse: data.newPassword,
      });
      handleSignInStep(signInStep);
    } catch (e) {
      console.info(e);
      // @ts-expect-error todo resolve
      switch (e.name) {
        case "UserNotFoundException":
          setRootError("このメールアドレスは登録されていません。");
          break;
        case "NotAuthorizedException":
          setRootError("メールアドレスかパスワードが間違っています。");
          break;
        case "UserNotConfirmedException":
          toast.error(
            "メールアドレスが未確認です。確認コードを入力してください。"
          );
          break;
        case "UserAlreadyAuthenticatedException":
          toast.error("既にログインしています。");
          navigate("/");
          window.location.reload();
          break;
        default:
          setRootError(
            "不明なエラーが発生しました。管理者にお問い合わせください。"
          );
          break;
      }
    }
  };
  return { sender };
};
