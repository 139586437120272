import { useFetcherWithSwr, useSender } from "@/api";
import { isAuthAdminAtom, isAuthStaffAtom } from "@/atoms";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { getJstFromUnixtime } from "@/utils";
import { useAtomValue } from "jotai";
import { PlusIcon, Trash2Icon } from "lucide-react";
import { QRCodeSVG } from "qrcode.react";

export const TicketCard = ({ userId }: { userId: string }) => {
  const { data: userEventCheckIn, mutate: reloadUserEventCheckIn } =
    useFetcherWithSwr({
      apiName: "event_manager",
      serviceName: "users",
      operationId: "getUserEventCheckIn",
      requestParameters: { userId },
      swrConfiguration: { errorRetryCount: 1 },
    });
  const {
    sender: createUserEventCheckIn,
    isLoading: isCreateUserEventCheckInPending,
  } = useSender({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "createUserEventCheckIn",
  });
  const {
    sender: deleteUserEventCheckIn,
    isLoading: isDeleteUserEventCheckInPending,
  } = useSender({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "deleteUserEventCheckIn",
  });

  const handleCreateUserEventCheckIn = async () => {
    await createUserEventCheckIn({ userId });
    reloadUserEventCheckIn();
  };
  const handleDeleteUserEventCheckIn = async () => {
    await deleteUserEventCheckIn({ userId });
    reloadUserEventCheckIn();
  };

  const isAuthAdmin = useAtomValue(isAuthAdminAtom);
  const isAuthStaff = useAtomValue(isAuthStaffAtom);

  const diplayCheckInButton = isAuthAdmin || isAuthStaff;

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center justify-between">
          受付QRコード
        </CardTitle>
        <CardDescription>受付時にQRコードをご提示ください。</CardDescription>
      </CardHeader>
      <CardContent className="grid">
        <QRCodeSVG value={userId} />
      </CardContent>
      <CardFooter className="grid gap-4">
        {userEventCheckIn && (
          <p className="text-sm text-green-600">
            {getJstFromUnixtime(userEventCheckIn.timestamp_created)}
            に受付しました。
          </p>
        )}
        {diplayCheckInButton && (
          <>
            {userEventCheckIn ? (
              <Button
                size="lg"
                onClick={handleDeleteUserEventCheckIn}
                disabled={isDeleteUserEventCheckInPending}
                variant="destructive"
              >
                <Trash2Icon className="mr-2 h-4 w-4" />
                チェックインを取り消す
              </Button>
            ) : (
              <Button
                size="lg"
                onClick={handleCreateUserEventCheckIn}
                disabled={isCreateUserEventCheckInPending}
              >
                <PlusIcon className="mr-2 h-4 w-4" />
                チェックイン
              </Button>
            )}
          </>
        )}
      </CardFooter>
    </Card>
  );
};
