import { Badge } from "@/components/ui/badge";
import { participantCategoryMap } from "@/constants";
import {
  ParticipantList,
  ParticipantType,
} from "@/generatedApi/eventManagerApi";

export const ParticipantListDisplay = ({
  participantList,
}: {
  participantList?: ParticipantList | undefined | null;
}) => {
  if (!participantList || participantList.items.length === 0) {
    return <p>参加者は登録されていません。</p>;
  } else {
    return (
      <ul className="grid gap-4">
        {participantList.items.map((participant, index) => (
          <li className="flex items-start justify-between" key={index}>
            <div className="">
              {participant.name}
              <br />
              <span className="text-muted-foreground">
                {participantCategoryMap[participant.participant_category]}
                {participant.school && `・${participant.school}`}
              </span>
            </div>
            <div className="flex flex-col items-end gap-1">
              <div className="flex items-center gap-1">
                {participant.participant_type === ParticipantType.APPLICANT && (
                  <Badge variant="default" className="bg-blue-600">
                    申込者
                  </Badge>
                )}
                <Badge variant="outline">参加者</Badge>
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  }
};
