import { appConfigAtom } from "@/atoms";
import { Alert } from "@/components/ui/alert";
import { buttonVariants } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { genderMap, participantCategoryMap } from "@/constants";
import { SecureUserAccount } from "@/generatedApi/eventManagerApi";
import { getJstDateFromUnixtime } from "@/utils";
import { useAtomValue } from "jotai";
import { ChevronRight, Edit2 } from "lucide-react";
import { Link } from "react-router-dom";

type SecureApplicantCardProps = {
  userId: string;
  secureUserAccount?: SecureUserAccount;
};
export const SecureApplicantCard = ({
  userId,
  secureUserAccount,
}: SecureApplicantCardProps) => {
  const applicant = secureUserAccount?.applicant;

  const appConfig = useAtomValue(appConfigAtom);
  if (!appConfig) return <>読み込み中...</>;
  return (
    <>
      {applicant ? (
        <Card>
          <CardHeader>
            <div className="mb-4 flex items-center justify-between">
              <div className="space-y-2">
                <CardTitle className="flex items-center gap-1">
                  申込者
                </CardTitle>
                <CardDescription>
                  {appConfig.flags.is_user_account_updatable ? (
                    <>
                      {getJstDateFromUnixtime(
                        Date.parse(appConfig.params.end_update_user_account)
                      )}
                      まで変更可能です。
                    </>
                  ) : (
                    <>変更受付期間外です。</>
                  )}
                </CardDescription>
              </div>
              {appConfig.flags.is_user_account_updatable && (
                <Link
                  to={`/users/${userId}/edit/applicant`}
                  className={buttonVariants({
                    variant: "secondary",
                    size: "sm",
                  })}
                >
                  <Edit2 className="mr-2 h-4 w-4" />
                  編集
                </Link>
              )}
            </div>
          </CardHeader>
          <CardContent>
            <div className="grid gap-3">
              <div>
                <div className="text-sm text-muted-foreground">氏名</div>
                <div className="">{applicant.name}</div>
              </div>
              <div>
                <div className="text-sm text-muted-foreground">電話番号</div>
                <div className="">{applicant.secure_phone}</div>
              </div>
              <div>
                <div className="text-sm text-muted-foreground">種別</div>
                <div className="">
                  {participantCategoryMap[applicant.participant_category]}
                </div>
              </div>
              <div>
                <div className="text-sm text-muted-foreground">学校名</div>
                <div className="">{applicant.school ?? "入力なし"}</div>
              </div>
              <div>
                <div className="text-sm text-muted-foreground">性別</div>
                <div className="">{genderMap[applicant.gender]}</div>
              </div>
              <div>
                <div className="text-sm text-muted-foreground">県・市町村</div>
                <div className="">{applicant.city}</div>
              </div>
            </div>
          </CardContent>
        </Card>
      ) : (
        <Card>
          <CardHeader>
            <div className="mb-4 flex items-center justify-between">
              <CardTitle className="flex items-center gap-1">
                申込者を登録してください
              </CardTitle>
            </div>
          </CardHeader>
          <CardFooter>
            {appConfig.flags.is_user_account_updatable ? (
              <Link
                to={`/users/${userId}/edit/applicant`}
                className={buttonVariants({})}
              >
                申込者情報を登録
                <ChevronRight className="ml-1 h-4 w-4" />
              </Link>
            ) : (
              <Alert variant="destructive">登録受付期間外です。</Alert>
            )}
          </CardFooter>
        </Card>
      )}
    </>
  );
};
