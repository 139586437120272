import { Badge } from "@/components/ui/badge";
import { Skeleton } from "@/components/ui/skeleton";

import { eventSessionTypeMap } from "@/constants";
import {
  ConfirmedReservation,
  EventSession,
  EventSessionType,
  ReservationRequest,
} from "@/generatedApi/eventManagerApi";
import {
  ChevronRight,
  FlagTriangleRightIcon,
  HandIcon,
  PresentationIcon,
} from "lucide-react";
import { useMemo } from "react";
import { Link } from "react-router-dom";

export type EventSessionSummaryCardProps = {
  eventSession?: EventSession;
  reservationRequestList?: ReservationRequest[];
  confirmedReservationList?: ConfirmedReservation[];
  link?: boolean;
  pathParams?: { [key: string]: string };
};
export const EventSessionSummaryCard = ({
  eventSession,
  reservationRequestList,
  confirmedReservationList,
  link = true,
}: EventSessionSummaryCardProps) => {
  const isCurrentSessionRequested = useMemo(
    () =>
      reservationRequestList?.some(
        (reservationRequest) =>
          reservationRequest.event_session_id === eventSession?.event_session_id
      ),
    [reservationRequestList, eventSession]
  );

  const currentSessionRequestOrder = useMemo(() => {
    if (!reservationRequestList || !eventSession) {
      return undefined;
    }
    const currentSessionRequest = reservationRequestList.find(
      (reservationRequest) =>
        reservationRequest.event_session_id === eventSession.event_session_id
    );
    return currentSessionRequest?.request_order;
  }, [reservationRequestList, eventSession]);

  ///

  const isCurrentSessionConfirmed = useMemo(
    () =>
      confirmedReservationList?.some(
        (confirmedReservation) =>
          confirmedReservation.event_session_id ===
          eventSession?.event_session_id
      ) ?? false,
    [confirmedReservationList, eventSession]
  );

  const sessionTypeColor = useMemo(() => {
    if (!eventSession) {
      return "bg-gray-600";
    }
    switch (eventSession.event_session_type) {
      case EventSessionType.LECTURE:
        return "bg-indigo-100";
      case EventSessionType.WORKSHOP:
        return "bg-green-100";
      case EventSessionType.TOUR:
        return "bg-yellow-100";
      default:
        return "bg-gray-100";
    }
  }, [eventSession]);

  const sessionTypeIcon = useMemo(() => {
    const commonClass = "h-4 w-4 mr-1";
    if (!eventSession) {
      return <PresentationIcon className={commonClass} />;
    }
    switch (eventSession.event_session_type) {
      case EventSessionType.LECTURE:
        return <PresentationIcon className={commonClass} />;
      case EventSessionType.WORKSHOP:
        return <HandIcon className={commonClass} />;
      case EventSessionType.TOUR:
        return <FlagTriangleRightIcon className={commonClass} />;
      default:
        return <PresentationIcon className={commonClass} />;
    }
  }, [eventSession]);

  return eventSession ? (
    <Link
      to={`/event_sessions/${eventSession.event_session_id}`}
      className={`display-block flex w-full items-center justify-between gap-1 rounded-md border p-3 text-left hover:bg-accent ${
        link ? "pointer-events-auto" : "pointer-events-none"
      }`}
    >
      <div className="grid flex-grow gap-2">
        <div className="flex items-center justify-between gap-1">
          <div className="flex items-center gap-1">
            <Badge className={`${sessionTypeColor}`} variant="secondary">
              {sessionTypeIcon}
              {eventSessionTypeMap[eventSession.event_session_type]}
            </Badge>
            {isCurrentSessionRequested &&
              (currentSessionRequestOrder ? (
                <Badge variant="default" className="bg-yellow-600">
                  第{currentSessionRequestOrder}希望中
                </Badge>
              ) : (
                <Badge variant="default" className="bg-yellow-600">
                  参加希望中
                </Badge>
              ))}
            {isCurrentSessionConfirmed && (
              <Badge variant="default" className="bg-green-600">
                参加確定
              </Badge>
            )}
          </div>
          <div className="text-xs text-muted-foreground">
            コード:
            {eventSession.event_session_id}
          </div>
        </div>
        <div className="font-semibold">
          {eventSession.name}
          <div className="text-sm text-muted-foreground">
            {eventSession.lecturer_name}
            {eventSession.lecturer_affiliation &&
              (eventSession.lecturer_name
                ? `（${eventSession.lecturer_affiliation}）`
                : eventSession.lecturer_affiliation)}
          </div>
        </div>
      </div>
      {link && <ChevronRight className="-mr-2 h-4 w-4" />}
    </Link>
  ) : (
    <div className="display-block flex w-full items-center justify-between gap-1 rounded-md border p-3 text-left">
      <Skeleton className="h-4 w-[250px]" />
    </div>
  );
};
