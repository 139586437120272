import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ChevronRight } from "lucide-react";
import React from "react";
import { useForm } from "react-hook-form";
import { AUTH_STEP, ConfirmSignInInput, useConfirmSignIn } from "./auth";

type ConfirmSignInFormProps = {
  setStep: React.Dispatch<React.SetStateAction<AUTH_STEP>>;
  setPassword: React.Dispatch<React.SetStateAction<string | undefined>>;
};
export const ConfirmSignInForm = ({
  setStep,
  setPassword,
}: ConfirmSignInFormProps) => {
  const {
    register: authRegister,
    handleSubmit: handleAuthSubmit,
    formState: { errors, isLoading },
    setError,
  } = useForm<ConfirmSignInInput>();
  const setRootError = (message: string) => {
    setError("root", { message: message });
  };
  const { sender } = useConfirmSignIn({
    setStep,
    setRootError,
  });
  const handleConfirmSignIn = (data: ConfirmSignInInput) => {
    setPassword(data.newPassword);
    sender({ newPassword: data.newPassword });
  };
  return (
    <form
      className="grid gap-4"
      text-left
      onSubmit={handleAuthSubmit(handleConfirmSignIn)}
    >
      <div className="grid gap-2">
        <Label htmlFor="password">新しいパスワード</Label>
        <Input
          {...authRegister("newPassword", {
            required: "新しいパスワードを入力してください",
          })}
          id="password"
          type="password"
        />
        <p className="text-red-400">{errors.newPassword?.message}</p>
      </div>
      <Button type="submit" disabled={isLoading}>
        ログイン <ChevronRight className="ml-1 h-4 w-4" />
      </Button>
      <p className="text-red-400">{errors.root?.message}</p>
    </form>
  );
};
