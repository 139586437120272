import { useFetcherWithSwr, useSender } from "@/api";
import { appConfigAtom } from "@/atoms";
import { Alert } from "@/components/ui/alert";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Separator } from "@/components/ui/separator";
import { ReservationRequest } from "@/generatedApi/eventManagerApi";
import { useTimeTableMap } from "@/hooks";
import { AutonomousEventSessionSummaryCard } from "@/pages/EventSessionListPage/EventSessionSummaryCard/AutonomousEventSessionSummaryCard";
import { useAtomValue } from "jotai";
import { PlusIcon, Trash2Icon } from "lucide-react";
import { Fragment, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "sonner";
type UserReservationRequestListProps = {
  userId: string;
};
export const UserReservationRequestList = ({
  userId,
}: UserReservationRequestListProps) => {
  const {
    data: reservationRequestList,
    isLoading: isGetReservationRequestListPending,
  } = useFetcherWithSwr({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "getReservationRequestListByUserId",
    requestParameters: { userId },
  });

  const timeSlotReservationRequestList = useMemo(() => {
    const res: { [timeSlot: number]: ReservationRequest[] } = {};
    reservationRequestList?.forEach((r) => {
      if (!res[r.time_slot]) {
        res[r.time_slot] = [];
      }
      res[r.time_slot].push(r);
    });
    return res;
  }, [reservationRequestList]);

  ///

  const [isAllReservationRequestDeleting, setIsAllReservationRequestDeleting] =
    useState(false);

  const { sender: deleteReservationRequest } = useSender({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "deleteReservationRequest",
  });

  const handleDeleteAllReservationRequest = useCallback(async () => {
    if (!reservationRequestList || reservationRequestList.length === 0) {
      toast.error("削除対象がありません");
      return;
    }

    setIsAllReservationRequestDeleting(true);
    await Promise.all(
      reservationRequestList.map((r) =>
        deleteReservationRequest({
          reservationId: r.reservation_id,
          userId,
        })
      )
    );
    setIsAllReservationRequestDeleting(false);
    window.location.reload();
  }, [deleteReservationRequest, reservationRequestList, userId]);

  const hasReservation = useMemo(
    () => reservationRequestList?.length !== 0,
    [reservationRequestList]
  );

  const timeTableMap = useTimeTableMap();

  const appConfig = useAtomValue(appConfigAtom);
  if (!appConfig) return <>読み込み中...</>;

  return (
    <>
      {!appConfig.flags.is_reservation_request_updatable && (
        <Alert>
          参加希望の受付期間外です。
          {appConfig.flags.is_confirmed_reservation_updatable &&
            "セッションの参加枠に空きがある場合、参加登録できます。"}
        </Alert>
      )}

      {isGetReservationRequestListPending ? (
        "読み込み中..."
      ) : (
        <div className="grid gap-4">
          {[1, 2, 3, 4, 5].map((timeSlot, index) => (
            <Fragment key={timeSlot}>
              {index !== 0 && <Separator />}
              <div className="grid gap-2">
                <div className="mb-4 flex gap-0.5">
                  <div className="bg-slate-600 px-2 text-white">
                    {timeSlot}時間目
                  </div>
                  <div className="rounded-br-sm rounded-tr-sm bg-slate-600 px-2 text-white">
                    {timeTableMap[timeSlot]}
                  </div>
                </div>
                <div className="grid gap-2">
                  {(!timeSlotReservationRequestList[timeSlot] ||
                    timeSlotReservationRequestList[timeSlot]?.length === 0) &&
                    "登録がありません。"}
                  {timeSlotReservationRequestList[timeSlot]?.map(
                    (reservationRequest) => (
                      <div
                        key={reservationRequest.reservation_id}
                        className="-mx-3"
                      >
                        <AutonomousEventSessionSummaryCard
                          eventSessionId={reservationRequest.event_session_id}
                          reservationRequestList={reservationRequestList}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      )}
      {appConfig.flags.is_reservation_request_updatable ? (
        <>
          <Link
            to={`/event_sessions`}
            className={buttonVariants({ size: "sm" })}
          >
            <PlusIcon className="mr-2 h-4 w-4" />
            セッション一覧から追加登録
          </Link>
          <Dialog>
            <DialogTrigger asChild>
              <div>
                <Button
                  variant="outline"
                  size="sm"
                  disabled={isAllReservationRequestDeleting || !hasReservation}
                >
                  <Trash2Icon className="mr-1 h-4 w-4" />
                  参加希望を一括削除
                </Button>
              </div>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
              <DialogHeader>
                <DialogTitle>登録を一括削除する</DialogTitle>
                <DialogDescription>
                  {reservationRequestList?.length ?? 0}
                  件の登録を一括削除します。
                </DialogDescription>
              </DialogHeader>
              <DialogFooter>
                <Button
                  variant="destructive"
                  disabled={isAllReservationRequestDeleting}
                  onClick={handleDeleteAllReservationRequest}
                >
                  {isAllReservationRequestDeleting
                    ? "処理中..."
                    : "一括削除する"}
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
