import { appConfigAtom } from "@/atoms";
import { useAtomValue } from "jotai";
import { useMemo } from "react";

type CapacityDisplayBaseProps = {
  capacityFilledRate?: number;
  participantCount?: number;
  maxCapacity: number;
  capacitySuffix: string;
  showCapacityFilled?: boolean;
};
export const CapacityDisplayBase = ({
  capacityFilledRate,
  participantCount,
  maxCapacity,
  capacitySuffix,
  showCapacityFilled = true,
}: CapacityDisplayBaseProps) => {
  const appConfig = useAtomValue(appConfigAtom);

  const label = useMemo(() => {
    if (capacityFilledRate === undefined) {
      return "読み込み中...";
    }
    if (!appConfig) {
      return "読み込み中...";
    }
    if (!showCapacityFilled) {
      return (
        <span>
          {participantCount}
          {capacitySuffix}
        </span>
      );
    }
    if (capacityFilledRate >= 10000) {
      return <span className="text-red-600">満員</span>;
    }
    if (
      capacityFilledRate >
      appConfig.params.capacity_almost_filled_percent_threshold * 100
    ) {
      return <span className="text-yellow-600">ほぼ満員</span>;
    }
    return (
      <span>
        {participantCount}
        {capacitySuffix}
      </span>
    );
  }, [
    capacityFilledRate,
    appConfig,
    participantCount,
    capacitySuffix,
    showCapacityFilled,
  ]);
  return (
    <div className="flex justify-start gap-1">
      <div>空き状況: </div>
      <div>
        {label} / 定員{maxCapacity}名
      </div>
    </div>
  );
};
