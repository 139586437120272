/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReservationRequest } from '../models/ReservationRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ReservationRequestsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Reservation Request
     * @returns ReservationRequest Successful Response
     * @throws ApiError
     */
    public getReservationRequest({
        reservationId,
        userId,
    }: {
        reservationId: string,
        userId?: (string | null),
    }): CancelablePromise<ReservationRequest> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/reservation_requests/{reservation_id}',
            path: {
                'reservation_id': reservationId,
            },
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
