import { buttonVariants } from "@/components/ui/button";
import { ChevronLeft } from "lucide-react";
import { Helmet } from "react-helmet";

import { useSender } from "@/api";
import { appConfigAtom } from "@/atoms";
import { useAtomValue } from "jotai";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { ParticipantListCard } from "../UserSinglePage/ParticipantListCard";
import { TicketCard } from "./TicketCard";
import { UserReservationListCard } from "./UserReservationListCard";

export const UserSingleReservationPage = () => {
  const { userId } = useParams<{ userId: string }>();

  const { data: userAccount, sender: getUserAccount } = useSender({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "getUserAccount",
  });
  useEffect(() => {
    if (userId) {
      getUserAccount({ userId });
    }
  }, [userId, getUserAccount]);
  const appConfig = useAtomValue(appConfigAtom);
  if (!appConfig) return <>読み込み中...</>;

  return (
    <>
      <Helmet>
        <title>参加予約</title>
      </Helmet>
      <div className="mb-8">
        <Link
          className={buttonVariants({ variant: "secondary", size: "sm" })}
          to={"/"}
        >
          <ChevronLeft className="mr-1 h-4 w-4" />
          トップページへ戻る
        </Link>
      </div>
      <div className="mb-4 grid gap-4">
        <h2 className="text-2xl font-semibold tracking-tight">参加予約</h2>
      </div>
      <div className="grid gap-4">
        {userId && (
          <>
            {appConfig.flags.display_ticket && <TicketCard userId={userId} />}
            <ParticipantListCard userId={userId} userAccount={userAccount} />
            <UserReservationListCard userId={userId} />
          </>
        )}
      </div>
    </>
  );
};
