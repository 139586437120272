/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EventSessionType {
    LECTURE = 'lecture',
    WORKSHOP = 'workshop',
    TOUR = 'tour',
    OTHER = 'other',
}
