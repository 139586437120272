import { buttonVariants } from "@/components/ui/button";
import { ChevronLeft } from "lucide-react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export const PrivacyPolicyPage = () => {
  return (
    <div className="mx-auto max-w-md">
      <Helmet>
        <title>プライバシーポリシー</title>
      </Helmet>
      <Link
        to="/"
        className={`${buttonVariants({ variant: "secondary" })} mb-4`}
      >
        <ChevronLeft className="mr-1 h-4 w-4" />
        トップページ
      </Link>
      <h1 className="mb-2 text-2xl font-semibold">プライバシーポリシー</h1>
      <p className="mb-2">
        株式会社Hashup（以下、「当社」という。）は、「イベント管理受付サービス」（以下、「当サービス」という。）におけるユーザーの個人情報について以下のとおりプライバシーポリシーを定めます。
      </p>
      <h3 className="mb-2 font-semibold">個人情報の利用目的</h3>
      <p className="mb-2">
        当サービスでは、アカウント登録の際に当社が取得する名前やメールアドレス等の個人情報は、以下に掲げる利用目的のために取得します。その他の場合において個人情報を取得する場合は、Webや書面で利用目的を明示し同意を得て取得します。
      </p>
      <ol className="mb-2 list-inside list-decimal space-y-2 pl-4">
        <li>本サービスを円滑に提供するため</li>
        <li>本サービスの運営・管理のため</li>
        <li>
          本サービスの利用状況等を調査および分析し、本サービスの改善、開発または利用者ごとの最適化を行うため
        </li>
        <li>本サービスに関して利用者と連絡を取るため</li>
        <li>本サービスに関するご案内、お問い合わせ等への対応のため</li>
        <li>当社が提供するサービスの保守・メンテナンス業務を行うため</li>
        <li>当社サービスに関する規約等の変更などを通知するため</li>
        <li>本サービスの効果測定を行うため</li>
        <li>その他当社と利用者との間で同意した目的のため</li>
        <li>上記 1 から 9 に附随する目的のため</li>
      </ol>
      <h3 className="mb-2 font-semibold">第三者提供について</h3>
      <p className="mb-2">
        当社は、以下の提供先に対して、書面または電磁的な方法により個人情報を提供します。
        なお、名古屋大学様と弊社間で個人情報の提供に関する契約を締結しております。
        弊社はイベント終了後、本サービスを通して取得したデータの全てを名古屋大学様に提出後、本件に関わる全ての個人情報を削除いたします。
      </p>
      <p className="mb-2">
        【提供先】
        <br />
        名古屋大学
      </p>
      <p className="mb-2">
        【提供情報】
        <br />
        本サービスの提供先である名古屋大学様に対して、本サービスで取得した個人情報を提供します。
      </p>
      <p className="mb-2">
        【提供目的】
        <br />
        個人情報を提供以後、名古屋大学様の責任において、登録された氏名、メールアドレスその他の個人情報は、集計され、個人が特定されない形(例えば参加者統計など)でイベントの報告書などに掲載されることがあります。
      </p>
      <h3 className="mb-2 font-semibold">
        個人を特定しない属性情報・行動履歴の取得及び利用について
      </h3>
      <p className="mb-2">
        当社は、利用者のプライバシーの保護、利便性の向上、広告の配信、及び統計データの取得のため、Cookie等(*)の情報を送信しています。また、当社は、Cookie等の技術を利用して、会員登録時等にご提供いただいた情報のうち年齢や性別、職業、居住地域など個人が特定できない属性情報(組み合わせることによっても個人が特定できないものに限られます)や、サイト内における利用者の行動履歴(アクセスしたURL、コンテンツ、参照順等)を取得することがあります。ただし、Cookie等及び行動履歴には個人情報は一切含まれません。なお、Cookie等の受け取りは、ブラウザの設定によって拒否することができます。拒否した場合本サービスをご利用いただく上で、一部機能に制約が生じることがあります。
      </p>
      <p className="mb-2">
        (*)...
        Cookie等とは、利用者がウェブサイトにアクセスした際に利用者のデバイスに保存されるテキストファイルおよびこれに類似する技術によって保存される電子ファイルをいいます。
      </p>
      <p className="mb-2">
        以下、当社において、どのような情報を、誰に対し、どのような目的で送信しているかを、記載いたします。
      </p>
      <h4 className="mb-2 font-semibold">Googleアナリティクス</h4>
      <ul>
        <li>
          送信される情報：UserAgent情報、リファラ、おおよその位置情報、セッションの統計情報
        </li>
        <li>サービス提供者：Google LLC</li>
        <li>当社の利用目的：アクセス解析のため</li>
        <li>送信先の利用目的：利用者による閲覧の傾向や履歴の分析のため</li>
      </ul>
      <p className="mb-2">
        詳細については以下のURLからご確認ください。
        <br />
        <a
          className="underline underline-offset-4"
          target="_blank"
          rel="noopener noreferrer"
          href="https://marketingplatform.google.com/about/analytics/terms/jp/"
        >
          Google アナリティクス利用規約
        </a>
      </p>
      <h3 className="mb-2 font-semibold">個人情報の開示・訂正・利用停止等</h3>
      <p className="mb-2">
        開示、訂正、利用停止等のお申し出があった場合には、当社所定の方法に基づき対応致します。具体的な方法については、個別にご案内しますので、下記受付窓口までお問い合わせください。
        <br />
        連絡先: events X hashup.co.jp (X → @)
      </p>
      <h3 className="mb-2 font-semibold">
        個人情報取り扱いに関する相談や苦情の連絡先
      </h3>
      <p className="mb-2">
        当社の個人情報の取り扱いに関するご質問やご不明点、苦情、その他のお問い合わせは下記受付窓口よりご連絡ください。
        <br />
        連絡先: events X hashup.co.jp (X → @)
      </p>
      <h3 className="mb-2 font-semibold">
        プライバシーポリシーの制定日及び改定日
      </h3>
      <p className="mb-2">
        制定：2024年5月17日
        <br />
      </p>
    </div>
  );
};
