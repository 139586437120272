import { useFetcherWithSwr } from "@/api";
import {
  appConfigAtom,
  currentAuthUserAtom,
  isAuthAdminAtom,
  isAuthStaffAtom,
} from "@/atoms";
import { Button, buttonVariants } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { signOut } from "aws-amplify/auth";
import { useAtomValue } from "jotai";
import { LogOutIcon, MenuIcon } from "lucide-react";
import { useTransition } from "react";
import { Link, Outlet, ScrollRestoration, useNavigate } from "react-router-dom";

export const AuthCommonShell = () => {
  const currentAuthUser = useAtomValue(currentAuthUserAtom);
  const userId = currentAuthUser?.userId;
  const [isLogouting, startLogout] = useTransition();

  const isAuthAdmin = useAtomValue(isAuthAdminAtom);
  const isAuthStaff = useAtomValue(isAuthStaffAtom);

  const navigate = useNavigate();

  const handleLogout = async () => {
    startLogout(() => {
      signOut().then(() => {
        navigate("/");
        window.location.reload();
      });
    });
  };
  const { data: version, error } = useFetcherWithSwr({
    apiName: "event_manager",
    serviceName: "default",
    operationId: "getVersion",
    requestParameters: {},
  });

  const appConfig = useAtomValue(appConfigAtom);

  return (
    <div className="flex min-h-screen w-full flex-col bg-background">
      <header className="sticky top-0 border-b border-border bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
        <div className="mx-auto flex h-12 max-w-md items-center justify-between p-4">
          <div className="flex items-center gap-2">
            <img src="/minilogo.png" alt="logo" className="h-6 w-auto" />
            <h1 className="font-semibold">
              <Link to="/">{appConfig?.displays.event_short_name}</Link>
            </h1>
            {isAuthAdmin && <div className="">管理者</div>}
            {isAuthStaff && <div className="">スタッフ</div>}
          </div>
          <Sheet>
            <SheetTrigger asChild>
              <Button variant="ghost" size="icon">
                <MenuIcon className="h-4 w-4" />
              </Button>
            </SheetTrigger>
            <SheetContent side="top">
              <SheetHeader>
                <SheetTitle className="text-center">
                  <h2 className="font-semibold">
                    {appConfig?.displays.event_short_name}
                  </h2>
                </SheetTitle>
                <SheetDescription>
                  <div className="text-center">
                    {isAuthAdmin && <div className="">管理者</div>}
                    {isAuthStaff && <div className="">スタッフ</div>}
                  </div>
                </SheetDescription>
              </SheetHeader>
              <div className="mt-2 grid gap-2">
                {[
                  { to: `/`, text: "トップ" },
                  { to: `/users/${userId}`, text: "参加者情報" },
                  { to: `/event_sessions`, text: "セッション一覧" },
                  { to: `/users/${userId}/reservations`, text: "予約一覧" },
                  { to: "/privacy", text: "プライバシーポリシー" },
                ].map((value) => (
                  <SheetClose asChild key={value.to}>
                    <Link
                      to={value.to}
                      className={buttonVariants({
                        variant: "ghost",
                      })}
                    >
                      {value.text}
                    </Link>
                  </SheetClose>
                ))}
                {(isAuthAdmin || isAuthStaff) && (
                  <>
                    <Separator />
                    {[
                      { to: `/users`, text: "参加者一覧" },
                      { to: `/reception_app`, text: "受付アプリ (beta)" },
                    ].map((value) => (
                      <SheetClose asChild key={value.to}>
                        <Link
                          to={value.to}
                          className={buttonVariants({
                            variant: "ghost",
                          })}
                        >
                          {value.text}
                        </Link>
                      </SheetClose>
                    ))}
                  </>
                )}
                <Separator />
                <Button
                  variant="ghost"
                  disabled={isLogouting}
                  onClick={() => handleLogout()}
                >
                  ログアウト
                  <LogOutIcon />
                </Button>
              </div>
            </SheetContent>
          </Sheet>
        </div>
      </header>
      <div>
        <div className="mx-auto max-w-md p-4">
          {isLogouting ? <>ログアウト中...</> : <Outlet />}
          <div className="mt-16 grid gap-4 border-t pt-4 text-center">
            <div>
              <Button
                size="sm"
                variant="secondary"
                disabled={isLogouting}
                onClick={() => handleLogout()}
              >
                ログアウト
                <LogOutIcon />
              </Button>
            </div>
            <div className="text-center text-xs text-muted-foreground/50">
              <p>
                <Link to="/privacy">プライバシーポリシー</Link>
              </p>
              <p>{appConfig?.displays.event_full_name}</p>
              <p>
                技術協力:
                <a
                  href={appConfig?.displays.hosting_organization_url}
                  target="_blank"
                >
                  {appConfig?.displays.hosting_organization}
                </a>
              </p>
              <p>
                {error ? (
                  <span className="text-red-300">エラー</span>
                ) : (
                  <>ver. {version ? version : "loading..."}</>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <ScrollRestoration />
    </div>
  );
};
