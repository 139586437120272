import { appConfigAtom } from "@/atoms";
import { Alert } from "@/components/ui/alert";
import { buttonVariants } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { SecureUserAccount } from "@/generatedApi/eventManagerApi";
import { getJstDateFromUnixtime } from "@/utils";
import { useAtomValue } from "jotai";
import { ChevronRight, Edit2 } from "lucide-react";
import { Link } from "react-router-dom";

type SecureEmergencyContactCardProps = {
  userId: string;
  secureUserAccount?: SecureUserAccount;
};
export const SecureEmergencyContactCard = ({
  userId,
  secureUserAccount,
}: SecureEmergencyContactCardProps) => {
  const emergencyContact = secureUserAccount?.emergency_contact;

  const appConfig = useAtomValue(appConfigAtom);
  if (!appConfig) return <>読み込み中...</>;
  return (
    <>
      {emergencyContact ? (
        <Card>
          <CardHeader>
            <div className="mb-4 flex items-center justify-between">
              <div className="space-y-2">
                <CardTitle className="flex items-center gap-1">
                  緊急連絡先
                </CardTitle>
                <CardDescription>
                  {appConfig.flags.is_user_account_updatable ? (
                    <>
                      {getJstDateFromUnixtime(
                        Date.parse(appConfig.params.end_update_user_account)
                      )}
                      まで変更可能です。
                    </>
                  ) : (
                    <>変更受付期間外です。</>
                  )}
                </CardDescription>
              </div>
              {appConfig.flags.is_user_account_updatable && (
                <Link
                  to={`/users/${userId}/edit/emergency_contact`}
                  className={buttonVariants({
                    variant: "secondary",
                    size: "sm",
                  })}
                >
                  <Edit2 className="mr-2 h-4 w-4" />
                  編集
                </Link>
              )}
            </div>
          </CardHeader>
          <CardContent>
            <div className="grid gap-3">
              <div>
                <div className="text-sm text-muted-foreground">氏名</div>
                <div className="">{emergencyContact.secure_name}</div>
              </div>
              <div>
                <div className="text-sm text-muted-foreground">電話番号</div>
                <div className="">{emergencyContact.secure_phone}</div>
              </div>
              <div>
                <div className="text-sm text-muted-foreground">
                  参加者との関係
                </div>
                <div className="">{emergencyContact.secure_relationship}</div>
              </div>
            </div>
          </CardContent>
        </Card>
      ) : (
        <Card>
          <CardHeader>
            <div className="mb-4 flex items-center justify-between">
              <CardTitle className="flex items-center gap-1">
                緊急連絡先を登録してください
              </CardTitle>
            </div>
          </CardHeader>
          <CardContent>
            <p>お子様だけで参加の場合は必ず入力してください</p>
          </CardContent>
          <CardFooter>
            {appConfig.flags.is_user_account_updatable ? (
              <Link
                to={`/users/${userId}/edit/emergency_contact`}
                className={buttonVariants({})}
              >
                緊急連絡先を登録
                <ChevronRight className="ml-1 h-4 w-4" />
              </Link>
            ) : (
              <Alert variant="destructive">登録受付期間外です。</Alert>
            )}
          </CardFooter>
        </Card>
      )}
    </>
  );
};
