import { useFetcherWithSwr } from "@/api";
import { appConfigAtom } from "@/atoms";
import { buttonVariants } from "@/components/ui/button";
import { useAtomValue } from "jotai";
import { ChevronLeft } from "lucide-react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { ParticipantListCard } from "./ParticipantListCard";
import { SecureApplicantCard } from "./SecureApplicantCard";
import { SecureEmergencyContactCard } from "./SecureEmergencyContactCard";
import { SecureUserAccountCard } from "./SecureUserAccountCard";

export const UserSinglePage = () => {
  const { userId } = useParams<{ userId: string }>();
  const { data: secureUserAccount } = useFetcherWithSwr({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "getSecureUserAccount",
    requestParameters: { userId: userId ?? "" },
  });
  const appConfig = useAtomValue(appConfigAtom);
  if (!appConfig) return <>読み込み中...</>;

  return (
    <div className="grid gap-4">
      <Helmet>
        <title>参加者情報</title>
      </Helmet>
      <div className="mb-8">
        <Link
          className={buttonVariants({ variant: "secondary", size: "sm" })}
          to={"/"}
        >
          <ChevronLeft className="mr-1 h-4 w-4" />
          トップページへ戻る
        </Link>
      </div>
      <div className="mb-4 flex items-center justify-between">
        <h2 className="text-2xl font-semibold tracking-tight">参加者情報</h2>
      </div>
      {userId && (
        <>
          <SecureUserAccountCard secureUserAccount={secureUserAccount} />
          <SecureApplicantCard
            userId={userId}
            secureUserAccount={secureUserAccount}
          />
          {secureUserAccount?.applicant && (
            <ParticipantListCard
              userId={userId}
              userAccount={secureUserAccount}
            />
          )}
          <SecureEmergencyContactCard
            userId={userId}
            secureUserAccount={secureUserAccount}
          />
        </>
      )}
    </div>
  );
};
