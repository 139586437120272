import { AuthUser } from "@aws-amplify/auth";
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { Result } from "react-zxing";
import { AppConfig } from "./generatedApi/eventManagerApi";

export const currentAuthUserAtom = atom<AuthUser | null>(null);

export const isLoggedInAtom = atom<boolean>((get) => {
  return get(currentAuthUserAtom) !== null;
});

export const authUserGroupAtom = atom<string[] | null>(null);

export const isAuthAdminAtom = atom<boolean>((get) => {
  const authUserGroup = get(authUserGroupAtom);
  return authUserGroup?.includes("admin") ?? false;
});

export const isAuthStaffAtom = atom<boolean>((get) => {
  const authUserGroup = get(authUserGroupAtom);
  return authUserGroup?.includes("staff") ?? false;
});

export const appConfigAtom = atom<AppConfig | null>(null);

export const targetDeviceIdAtom = atomWithStorage<string | undefined>(
  "targetDeviceId",
  undefined
);

export const isAutoCheckinAtom = atomWithStorage<boolean>("isAutoCheckin", false);
export const checkInTypeAtom = atomWithStorage<'event' | 'session'>("checkInType", "event");
export const checkInEventIdAtom = atomWithStorage<string | undefined>('checkInEventId', undefined);

export const scannedResultQueueAtom = atom<Result[]>([]);
