import { appConfigAtom } from "@/atoms";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useAtomValue } from "jotai";
import { ChevronRight } from "lucide-react";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import {
  AUTH_STEP,
  ConfirmSignUpInput,
  useConfirmSignUp,
  useResendSignUpCode,
} from "./auth";

type ConfirmSignUpFormProps = {
  setConfirmationCode: React.Dispatch<React.SetStateAction<string | undefined>>;
  setStep: React.Dispatch<React.SetStateAction<AUTH_STEP>>;
  email?: string;
  password?: string;
};
export const ConfirmSignUpForm = ({
  setConfirmationCode,
  setStep,
  email,
  password,
}: ConfirmSignUpFormProps) => {
  const {
    register: authRegister,
    handleSubmit: handleAuthSubmit,
    formState: { errors, isLoading },
    setError,
  } = useForm<ConfirmSignUpInput>();

  const setRootError = (message: string) => {
    setError("root", { message: message });
  };

  const { sender } = useConfirmSignUp({ setRootError, setStep });

  const handleSignUp = async (data: ConfirmSignUpInput) => {
    if (!email || !password) {
      setStep("SIGN_UP");
      toast.error("エラーが発生しました。もう一度やり直してください。");
      return;
    }
    setConfirmationCode(data.confirmationCode);
    await sender({
      email: data.email,
      confirmationCode: data.confirmationCode,
      password,
    });
  };

  const [resendDisiabledSeconds, setResendDisabledSeconds] = React.useState(0);

  const { sender: resendSignUpCode } = useResendSignUpCode({ setRootError });

  const handleResendSignUpCode = async () => {
    if (!email) {
      toast.error("エラーが発生しました。もう一度やり直してください。");
      window.location.reload();
      return;
    }
    await resendSignUpCode({ email });
    setResendDisabledSeconds(60);
    const interval = setInterval(() => {
      setResendDisabledSeconds((prev) => prev - 1);
    }, 1000);
    setTimeout(() => {
      clearInterval(interval);
    }, 60000);
  };

  const appConfig = useAtomValue(appConfigAtom);
  if (!appConfig) {
    return <></>;
  }

  return (
    <form
      className="grid gap-4"
      text-left
      onSubmit={handleAuthSubmit(handleSignUp)}
    >
      <p className="text-sm">
        {appConfig.displays.sender_email}
        からお送りしたメールに書かれている認証コードを入力して、登録ボタンを押してください。
        数分待ってもメールが届いていなければ、登録したメールアドレスが正しいかを確認してください。
        間違えている場合はキャンセルボタンを押して前の画面に戻り、正しいメールアドレスで再設定してください。
        メールアドレスが正しければ、コードを再送信するボタンを押してみてください。迷惑メールフォルダなども確認してみてください。
      </p>
      <div className="grid gap-2">
        <Input
          type="hidden"
          value={password}
          {...authRegister("email", { value: email })}
        />
        <Label htmlFor="confirmationCode">確認コード</Label>
        <Input
          {...authRegister("confirmationCode", {
            required: "確認コードを入力してください",
          })}
          id="confirmationCode"
          type="text"
          placeholder="123456"
          disabled={isLoading}
        />
        <p className="text-red-400">{errors.confirmationCode?.message}</p>
      </div>
      <Button type="submit" disabled={isLoading}>
        登録 <ChevronRight className="ml-1 h-4 w-4" />
      </Button>
      <Button
        type="button"
        onClick={handleResendSignUpCode}
        variant="ghost"
        disabled={resendDisiabledSeconds > 0}
      >
        {resendDisiabledSeconds > 0
          ? `${resendDisiabledSeconds}秒後に再送信可能`
          : "確認コードを再送信"}
        <ChevronRight className="ml-1 h-4 w-4" />
      </Button>
      <p className="text-red-400">{errors.root?.message}</p>
    </form>
  );
};
