import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { SecureUserAccount } from "@/generatedApi/eventManagerApi";
import { getJstFromUnixtime } from "@/utils";

type SecureUserAccountCardProps = {
  secureUserAccount?: SecureUserAccount;
};
export const SecureUserAccountCard = ({
  secureUserAccount,
}: SecureUserAccountCardProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>アカウント</CardTitle>
        <CardDescription>こちらの情報は変更できません。</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="grid gap-3">
          <div>
            <div className="text-sm text-muted-foreground">受付番号</div>
            <div className="">
              {String(secureUserAccount?.user_number).padStart(4, "0")}
            </div>
          </div>
          <div>
            <div className="text-sm text-muted-foreground">メールアドレス</div>
            <div className="">{secureUserAccount?.secure_email}</div>
          </div>
          <div>
            <div className="text-sm text-muted-foreground">
              アカウント作成日時
            </div>
            <div className="">
              {getJstFromUnixtime(secureUserAccount?.timestamp_created)}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
