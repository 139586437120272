/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckIn } from '../models/CheckIn';
import type { ConfirmedReservation } from '../models/ConfirmedReservation';
import type { ConfirmedReservationCreate } from '../models/ConfirmedReservationCreate';
import type { ParticipantList } from '../models/ParticipantList';
import type { ParticipantListCreate } from '../models/ParticipantListCreate';
import type { ReservationRequest } from '../models/ReservationRequest';
import type { ReservationRequestCreate } from '../models/ReservationRequestCreate';
import type { SecureApplicant } from '../models/SecureApplicant';
import type { SecureApplicantCreate } from '../models/SecureApplicantCreate';
import type { SecureEmergencyContact } from '../models/SecureEmergencyContact';
import type { SecureEmergencyContactCreate } from '../models/SecureEmergencyContactCreate';
import type { SecureUserAccount } from '../models/SecureUserAccount';
import type { UserAccount } from '../models/UserAccount';
import type { UserAccountQueryParams } from '../models/UserAccountQueryParams';
import type { UserAccountQueryResponse } from '../models/UserAccountQueryResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get User Account List
     * @returns UserAccountQueryResponse Successful Response
     * @throws ApiError
     */
    public getUserAccountList({
        userId,
        requestBody,
    }: {
        userId?: (string | null),
        requestBody?: (UserAccountQueryParams | null),
    }): CancelablePromise<UserAccountQueryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/',
            query: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Total User Count
     * @returns number Successful Response
     * @throws ApiError
     */
    public getTotalUserCount({
        userId,
    }: {
        userId?: (string | null),
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/count',
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Total Check In Count
     * @returns number Successful Response
     * @throws ApiError
     */
    public getTotalCheckInCount({
        userId,
    }: {
        userId?: (string | null),
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/check_ins/count',
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Total Check In Participant Count
     * @returns number Successful Response
     * @throws ApiError
     */
    public getTotalCheckInParticipantCount({
        userId,
    }: {
        userId?: (string | null),
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/check_ins/participant_count',
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * ユーザーアカウントを作成します。
     * @returns SecureUserAccount Successful Response
     * @throws ApiError
     */
    public createUserAccount({
        userId,
        secureEmail,
    }: {
        userId: (string | null),
        secureEmail: string,
    }): CancelablePromise<SecureUserAccount> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/{user_id}',
            path: {
                'user_id': userId,
            },
            query: {
                'secure_email': secureEmail,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get User Account
     * @returns UserAccount Successful Response
     * @throws ApiError
     */
    public getUserAccount({
        userId,
    }: {
        userId: (string | null),
    }): CancelablePromise<UserAccount> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete User Account
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteUserAccount({
        userId,
    }: {
        userId: (string | null),
    }): CancelablePromise<(boolean | null)> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Secure User Account
     * @returns SecureUserAccount Successful Response
     * @throws ApiError
     */
    public getSecureUserAccount({
        userId,
    }: {
        userId: (string | null),
    }): CancelablePromise<SecureUserAccount> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{user_id}/secure',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Applicant
     * @returns SecureApplicant Successful Response
     * @throws ApiError
     */
    public updateApplicant({
        userId,
        requestBody,
    }: {
        userId: (string | null),
        requestBody: SecureApplicantCreate,
    }): CancelablePromise<SecureApplicant> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/users/{user_id}/applicant',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Applicant
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteApplicant({
        userId,
    }: {
        userId: (string | null),
    }): CancelablePromise<(boolean | null)> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/{user_id}/applicant',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Participant List
     * @returns ParticipantList Successful Response
     * @throws ApiError
     */
    public updateParticipantList({
        userId,
        requestBody,
    }: {
        userId: (string | null),
        requestBody: ParticipantListCreate,
    }): CancelablePromise<ParticipantList> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/users/{user_id}/participant_list',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Participant List
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteParticipantList({
        userId,
    }: {
        userId: (string | null),
    }): CancelablePromise<(boolean | null)> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/{user_id}/participant_list',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Emergency Contact
     * @returns SecureEmergencyContact Successful Response
     * @throws ApiError
     */
    public updateEmergencyContact({
        userId,
        requestBody,
    }: {
        userId: (string | null),
        requestBody: SecureEmergencyContactCreate,
    }): CancelablePromise<SecureEmergencyContact> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/users/{user_id}/emergency_contact',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Emergency Contact
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteEmergencyContact({
        userId,
    }: {
        userId: (string | null),
    }): CancelablePromise<(boolean | null)> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/{user_id}/emergency_contact',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Reservation Request
     * @returns ReservationRequest Successful Response
     * @throws ApiError
     */
    public createReservationRequest({
        userId,
        requestBody,
    }: {
        userId: (string | null),
        requestBody: ReservationRequestCreate,
    }): CancelablePromise<ReservationRequest> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/{user_id}/reservation_requests',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Reservation Request List By User Id
     * @returns ReservationRequest Successful Response
     * @throws ApiError
     */
    public getReservationRequestListByUserId({
        userId,
    }: {
        userId: (string | null),
    }): CancelablePromise<Array<ReservationRequest>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{user_id}/reservation_requests',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Reservation Request
     * @returns ReservationRequest Successful Response
     * @throws ApiError
     */
    public getReservationRequest({
        userId,
        reservationId,
    }: {
        userId: (string | null),
        reservationId: string,
    }): CancelablePromise<ReservationRequest> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{user_id}/reservation_requests/{reservation_id}',
            path: {
                'user_id': userId,
                'reservation_id': reservationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Reservation Request
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteReservationRequest({
        reservationId,
        userId,
    }: {
        reservationId: string,
        userId: (string | null),
    }): CancelablePromise<(boolean | null)> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/{user_id}/reservation_requests/{reservation_id}',
            path: {
                'reservation_id': reservationId,
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Confirmed Reservation
     * @returns ConfirmedReservation Successful Response
     * @throws ApiError
     */
    public createConfirmedReservation({
        userId,
        requestBody,
    }: {
        userId: (string | null),
        requestBody: ConfirmedReservationCreate,
    }): CancelablePromise<ConfirmedReservation> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/{user_id}/confirmed_reservations',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Confirmed Reservation List By User Id
     * @returns ConfirmedReservation Successful Response
     * @throws ApiError
     */
    public getConfirmedReservationListByUserId({
        userId,
    }: {
        userId: (string | null),
    }): CancelablePromise<Array<ConfirmedReservation>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{user_id}/confirmed_reservations',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Confirmed Reservation
     * @returns ConfirmedReservation Successful Response
     * @throws ApiError
     */
    public getConfirmedReservation({
        userId,
        reservationId,
    }: {
        userId: (string | null),
        reservationId: string,
    }): CancelablePromise<ConfirmedReservation> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{user_id}/confirmed_reservations/{reservation_id}',
            path: {
                'user_id': userId,
                'reservation_id': reservationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Confirmed Reservation
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteConfirmedReservation({
        reservationId,
        userId,
    }: {
        reservationId: string,
        userId: (string | null),
    }): CancelablePromise<(boolean | null)> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/{user_id}/confirmed_reservations/{reservation_id}',
            path: {
                'reservation_id': reservationId,
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create User Event Check In
     * @returns CheckIn Successful Response
     * @throws ApiError
     */
    public createUserEventCheckIn({
        userId,
    }: {
        userId: (string | null),
    }): CancelablePromise<CheckIn> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/{user_id}/check_in',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get User Event Check In
     * @returns CheckIn Successful Response
     * @throws ApiError
     */
    public getUserEventCheckIn({
        userId,
    }: {
        userId: (string | null),
    }): CancelablePromise<CheckIn> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{user_id}/check_in',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete User Event Check In
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteUserEventCheckIn({
        userId,
    }: {
        userId: (string | null),
    }): CancelablePromise<(boolean | null)> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/{user_id}/check_in',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create User Session Check In
     * @returns CheckIn Successful Response
     * @throws ApiError
     */
    public createUserSessionCheckIn({
        userId,
        reservationId,
    }: {
        userId: (string | null),
        reservationId: string,
    }): CancelablePromise<CheckIn> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/{user_id}/confirmed_reservations/{reservation_id}/check_in',
            path: {
                'user_id': userId,
                'reservation_id': reservationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get User Session Check In
     * @returns CheckIn Successful Response
     * @throws ApiError
     */
    public getUserSessionCheckIn({
        userId,
        reservationId,
    }: {
        userId: (string | null),
        reservationId: string,
    }): CancelablePromise<CheckIn> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{user_id}/confirmed_reservations/{reservation_id}/check_in',
            path: {
                'user_id': userId,
                'reservation_id': reservationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete User Session Check In
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteUserSessionCheckIn({
        userId,
        reservationId,
    }: {
        userId: (string | null),
        reservationId: string,
    }): CancelablePromise<(boolean | null)> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/{user_id}/confirmed_reservations/{reservation_id}/check_in',
            path: {
                'user_id': userId,
                'reservation_id': reservationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
