import { buttonVariants } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

import { ChevronRight } from "lucide-react";
import { Link } from "react-router-dom";

type EventSessionCardProps = {
  userId: string;
};
export const UserReservationCard = ({ userId }: EventSessionCardProps) => {
  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>現在の登録状況の確認</CardTitle>
        </CardHeader>
        <CardContent>
          <p>参加希望登録済みのセッションを確認することができます。</p>
        </CardContent>
        <CardFooter>
          <Link
            to={`/users/${userId}/reservations`}
            className={buttonVariants()}
          >
            現在の登録状況を確認する
            <ChevronRight className="ml-1 h-4 w-4" />
          </Link>
        </CardFooter>
      </Card>
    </>
  );
};
