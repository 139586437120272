import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ChevronRight } from "lucide-react";
import React from "react";
import { useForm } from "react-hook-form";
import {
    AUTH_STEP,
    ResetPasswordInput,
    useResetPassword
} from "./auth";

type ResetPasswordFormProps = {
  setStep: React.Dispatch<React.SetStateAction<AUTH_STEP>>;
  setEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
};
export const ResetPasswordForm = ({
  setStep,
  setEmail,
}: ResetPasswordFormProps) => {
  const {
    register: authRegister,
    handleSubmit: handleAuthSubmit,
    formState: { errors, isLoading },
    setError,
  } = useForm<ResetPasswordInput>();
  const setRootError = (message: string) => {
    setError("root", { message: message });
  };
  const { sender } = useResetPassword({
    setStep,
    setRootError,
  });
  const handleResetPassword = async (data: ResetPasswordInput) => {
    setEmail(data.email);
    await sender(data);
  };
  return (
    <form
      className="grid gap-4"
      text-left
      onSubmit={handleAuthSubmit(handleResetPassword)}
    >
      <div className="grid gap-2">
        <Label htmlFor="email">メールアドレス</Label>
        <Input
          {...authRegister("email", {
            required: "メールアドレスを入力してください",
          })}
          id="email"
          type="email"
          placeholder="example@hashup.co.jp"
        />
        <p className="text-red-400">{errors.email?.message}</p>
      </div>
      <Button type="submit" disabled={isLoading}>
        パスワードリセット <ChevronRight className="ml-1 h-4 w-4" />
      </Button>
      <p className="text-red-400">{errors.root?.message}</p>
    </form>
  );
};
