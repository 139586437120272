import { buttonVariants } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

import { ChevronRight } from "lucide-react";
import { Link } from "react-router-dom";

export const EventSessionCard = () => {
  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>セッション一覧から参加希望登録</CardTitle>
        </CardHeader>
        <CardContent>
          <p>
            セッション一覧表から参加希望のセッション(講義、体験教室、見学)を選んで参加登録を行います。
          </p>
        </CardContent>
        <CardFooter>
          <Link to={`/event_sessions/`} className={buttonVariants()}>
            参加希望を登録する
            <ChevronRight className="ml-1 h-4 w-4" />
          </Link>
        </CardFooter>
      </Card>
    </>
  );
};
