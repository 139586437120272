/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppConfig } from '../models/AppConfig';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * モジュールのバージョンを返します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public getVersion({
        userId,
    }: {
        userId?: (string | null),
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/version',
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * モジュールのバージョンを返します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public getStaffVersion({
        userId,
    }: {
        userId?: (string | null),
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/staff_version',
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * モジュールのバージョンを返します。
     * @returns any Successful Response
     * @throws ApiError
     */
    public getAdminVersion({
        userId,
    }: {
        userId?: (string | null),
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin_version',
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Config
     * @returns AppConfig Successful Response
     * @throws ApiError
     */
    public getConfig({
        userId,
    }: {
        userId?: (string | null),
    }): CancelablePromise<AppConfig> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/config',
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Raise Exception
     * @returns any Successful Response
     * @throws ApiError
     */
    public raiseException({
        userId,
    }: {
        userId?: (string | null),
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/errors/raise_exception',
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * エラーログを出力します
     * @returns any Successful Response
     * @throws ApiError
     */
    public putErrorLog({
        userId,
    }: {
        userId?: (string | null),
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/errors/put_error_log',
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * タイムアウトを起こします
     * @returns any Successful Response
     * @throws ApiError
     */
    public raiseTimeout({
        secs = 15,
        userId,
    }: {
        secs?: number,
        userId?: (string | null),
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/errors/raise_timeout',
            query: {
                'secs': secs,
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
