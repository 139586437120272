import { useFetcherWithSwr, useSender } from "@/api";
import { appConfigAtom } from "@/atoms";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button, buttonVariants } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  ConfirmedReservation,
  EventSession,
  UserAccount,
} from "@/generatedApi/eventManagerApi";
import { AutonomousEventSessionSummaryCard } from "@/pages/EventSessionListPage/EventSessionSummaryCard/AutonomousEventSessionSummaryCard";
import { getParticipantEligibleList } from "@/pages/common";
import { useAtomValue } from "jotai";
import {
  AlertCircle,
  ChevronRightIcon,
  PlusIcon,
  Trash2Icon,
} from "lucide-react";
import { useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";

type BodyProps = {
  eventSession: EventSession;
  userAccount: UserAccount;
  confirmedReservationList: ConfirmedReservation[];
  reloadUserData: () => void;
};
const Body = ({
  confirmedReservationList,
  eventSession,
  userAccount,
}: BodyProps) => {
  const navigate = useNavigate();
  const {
    sender: createConfirmedReservation,
    isLoading: isCreateConfirmedReservationPending,
  } = useSender({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "createConfirmedReservation",
  });

  const handleCreateConfirmedReservation = useCallback(async () => {
    const res = await createConfirmedReservation({
      userId: userAccount.user_id,
      requestBody: {
        event_session_id: eventSession.event_session_id,
        user_id: userAccount.user_id,
      },
    });
    if (res) {
      toast.success("参加登録しました");
      navigate(`/users/${userAccount.user_id}/reservations`);
    } else {
      toast.error("参加登録に失敗しました");
    }
  }, [createConfirmedReservation, userAccount, eventSession, navigate]);

  ///

  const {
    sender: deleteConfirmedReservation,
    isLoading: isDeleteConfirmedReservationPending,
  } = useSender({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "deleteConfirmedReservation",
  });
  const handleDeleteConfirmedReservation = useCallback(async () => {
    const reservationId = confirmedReservationList.find(
      (confirmedReservation) =>
        confirmedReservation.event_session_id === eventSession.event_session_id
    )?.reservation_id;
    if (!reservationId) {
      toast.error("参加登録の取り消しに失敗しました");
      return;
    }
    const res = await deleteConfirmedReservation({
      userId: userAccount.user_id,
      reservationId,
    });
    if (res) {
      toast.success("参加登録を取り消しました");
      navigate(`/users/${userAccount.user_id}/reservations`);
    } else {
      toast.error("参加登録の取り消しに失敗しました");
    }
  }, [
    deleteConfirmedReservation,
    userAccount,
    eventSession,
    confirmedReservationList,
    navigate,
  ]);

  ///

  const isCurrentSessionConfirmed = useMemo(() => {
    const res = confirmedReservationList.some(
      (confirmedReservation) =>
        confirmedReservation.event_session_id === eventSession.event_session_id
    );
    return res;
  }, [confirmedReservationList, eventSession]);

  const isCurrentTimeSlotConfirmed = useMemo(
    () =>
      confirmedReservationList.some(
        (confirmedReservation) =>
          confirmedReservation.time_slot === eventSession.time_slot
      ),
    [confirmedReservationList, eventSession]
  );
  const conflictingEventSessionId = useMemo(
    () =>
      confirmedReservationList.find(
        (confirmedReservation) =>
          confirmedReservation.time_slot === eventSession.time_slot &&
          confirmedReservation.event_session_id !==
            eventSession.event_session_id
      )?.event_session_id,
    [confirmedReservationList, eventSession]
  );

  ///

  const reservationEligible = useMemo(() => {
    if (
      !userAccount.participant_list ||
      userAccount.participant_list.items.length === 0
    ) {
      return undefined;
    }
    const res = getParticipantEligibleList(eventSession.eligibles, userAccount);
    const filtered = userAccount.participant_list?.items.map(
      (participant) => res[participant.participant_category]
    );
    return Object.values(filtered).every((v) => v);
  }, [userAccount, eventSession]);

  ///

  const { data: capacityFilledRate } = useFetcherWithSwr({
    apiName: "event_manager",
    serviceName: "eventSessions",
    operationId: "getEventSessionConfirmedReservationCapacityFilledRate",
    requestParameters: {
      userId: userAccount.user_id,
      eventSessionId: eventSession.event_session_id,
    },
  });

  const isCapacityFilled = useMemo(() => {
    if (!capacityFilledRate) {
      return undefined;
    }
    return capacityFilledRate > 10000;
  }, [capacityFilledRate]);

  const appConfig = useAtomValue(appConfigAtom);
  if (!appConfig) {
    return "読み込み中...";
  }

  return (
    <div className="grid gap-4">
      {!isCurrentSessionConfirmed && isCapacityFilled && (
        <Alert>
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>
            セッションは満員のため、参加を登録できません。
          </AlertDescription>
        </Alert>
      )}
      {!isCapacityFilled && !reservationEligible && (
        <Alert>
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>
            セッションの参加要件にを満たしていないため、参加登録できません。
          </AlertDescription>
        </Alert>
      )}
      {!isCapacityFilled &&
        !isCurrentTimeSlotConfirmed &&
        !isCurrentSessionConfirmed &&
        reservationEligible &&
        appConfig.flags.is_confirmed_reservation_updatable && (
          <>
            <Button
              onClick={handleCreateConfirmedReservation}
              disabled={isCreateConfirmedReservationPending}
            >
              <PlusIcon className="mr-1 h-4 w-4" />
              {isCreateConfirmedReservationPending
                ? "登録中..."
                : "参加登録する"}
            </Button>
          </>
        )}
      {!isCapacityFilled && conflictingEventSessionId && (
        <div className="grid gap-4">
          <p>
            同じ時間で下記のセッションを登録済みです。
            登録を取り消してから再度登録してください。
          </p>
          <div className="-mx-0">
            <AutonomousEventSessionSummaryCard
              eventSessionId={conflictingEventSessionId}
              confirmedReservationList={confirmedReservationList}
              link={false}
            />
          </div>
        </div>
      )}
      {isCurrentSessionConfirmed && (
        <div className="grid gap-4">
          <p>参加登録確定済みです</p>
          <div>
            {appConfig.flags.is_confirmed_reservation_updatable && (
              <Dialog>
                <DialogTrigger asChild>
                  <Button variant="outline" size="sm">
                    <Trash2Icon className="mr-1 h-4 w-4" />
                    参加登録を取り消す
                  </Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[425px]">
                  <DialogHeader>
                    <DialogTitle>参加登録を取り消す</DialogTitle>
                    <DialogDescription>
                      本当に参加登録を取り消しますか？
                      セッションによっては、取り消し後に再度登録できない場合があります。
                    </DialogDescription>
                  </DialogHeader>
                  <DialogFooter>
                    <DialogClose asChild>
                      <Button type="button" variant="secondary">
                        キャンセル
                      </Button>
                    </DialogClose>
                    <Button
                      variant="destructive"
                      onClick={handleDeleteConfirmedReservation}
                      disabled={isDeleteConfirmedReservationPending}
                    >
                      {isDeleteConfirmedReservationPending
                        ? "取り消し中..."
                        : "参加登録を取り消す"}
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            )}
          </div>
        </div>
      )}
      {!appConfig.flags.is_confirmed_reservation_updatable && (
        <Alert variant="destructive">
          セッション登録期間外なので、追加や変更ができません。
        </Alert>
      )}
    </div>
  );
};

type EventSessionSingleConfirmedReservationCardProps = {
  eventSession: EventSession;
  userAccount?: UserAccount;
  confirmedReservationList: ConfirmedReservation[];
  reloadUserData: () => void;
};
export const EventSessionSingleConfirmedReservationCard = ({
  eventSession,
  userAccount,
  confirmedReservationList,
  reloadUserData,
}: EventSessionSingleConfirmedReservationCardProps) => {
  const appConfig = useAtomValue(appConfigAtom);
  if (!appConfig) {
    return "読み込み中...";
  }
  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center justify-between">
          参加登録
          {userAccount && (
            <Link
              to={`/users/${userAccount.user_id}/reservations`}
              className={buttonVariants({ size: "sm", variant: "secondary" })}
            >
              登録内容一覧
              <ChevronRightIcon className="ml-1 h-4 w-4" />
            </Link>
          )}
        </CardTitle>
      </CardHeader>
      <CardContent className="grid gap-4">
        {appConfig.flags.is_confirmed_reservation_updatable ? (
          <>
            {!userAccount ||
            !userAccount.participant_list ||
            userAccount.participant_list.items.length < 1 ? (
              <div>
                参加者情報を登録すると、セッションの参加登録ができるようになります。
              </div>
            ) : (
              <Body
                confirmedReservationList={confirmedReservationList}
                eventSession={eventSession}
                reloadUserData={reloadUserData}
                userAccount={userAccount}
              />
            )}
          </>
        ) : (
          <Alert variant="destructive">
            セッションの参加登録期限を過ぎているため、追加や変更ができません。
          </Alert>
        )}
      </CardContent>
    </Card>
  );
};
