import { useSender } from "@/api";
import { appConfigAtom } from "@/atoms";
import { Alert } from "@/components/ui/alert";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { SecureEmergencyContactCreate } from "@/generatedApi/eventManagerApi";
import { useAtomValue } from "jotai";
import {
  CheckCircleIcon,
  ChevronLeft,
  SaveIcon,
  Trash2Icon,
} from "lucide-react";
import { useCallback } from "react";
import { Helmet } from "react-helmet";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

export const UserSingleEditEmergencyContactPage = () => {
  const { userId } = useParams<{ userId: string }>();

  const {
    sender: fetchSecureUserAccount,
    isLoading: isFetchSecureUserAccountPending,
    // error: fetchSecureUserAccountError,
  } = useSender({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "getSecureUserAccount",
  });

  const {
    sender: updateEmergencyContact,
    isLoading: isUpdateEmergencyContactPending,
    error: updateEmergencyContactError,
  } = useSender({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "updateEmergencyContact",
  });

  const loading =
    isFetchSecureUserAccountPending || isUpdateEmergencyContactPending;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SecureEmergencyContactCreate>({
    // @ts-expect-error todo
    defaultValues: async () => {
      const res = await fetchSecureUserAccount({ userId: userId ?? "" });
      return res?.emergency_contact;
    },
  });

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<SecureEmergencyContactCreate> = async (
    data
  ) => {
    if (!userId) return;
    const res = await updateEmergencyContact({ userId, requestBody: data });
    if (res) {
      toast.success("緊急連絡先を保存しました", {
        icon: <CheckCircleIcon className="h-4 w-4" />,
        duration: 3000,
      });
      navigate(`/users/${userId}`);
    } else {
      toast.error("緊急連絡先の保存に失敗しました");
    }
  };

  ///

  const {
    sender: deleteEmergencyContact,
    isLoading: isDeleteEmergencyContactPending,
  } = useSender({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "deleteEmergencyContact",
  });

  const handleDeleteEmergencyContact = useCallback(async () => {
    if (!userId) return;
    const res = await deleteEmergencyContact({ userId });
    if (res) {
      toast.success("緊急連絡先を削除しました", {
        duration: 3000,
      });
      navigate(`/users/${userId}`);
    } else {
      toast.error("緊急連絡先の削除に失敗しました");
    }
  }, [deleteEmergencyContact, userId, navigate]);

  const appConfig = useAtomValue(appConfigAtom);
  if (!appConfig) return <>読み込み中...</>;

  return (
    <>
      <Helmet>
        <title>編集</title>
      </Helmet>
      <div className="mb-8">
        <Link
          to={`/users/${userId}`}
          className={buttonVariants({ variant: "secondary", size: "sm" })}
        >
          <ChevronLeft className="mr-1 h-4 w-4" />
          参加者情報ページへ戻る
        </Link>
      </div>
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center justify-between gap-1">
            緊急連絡先
            {appConfig.flags.is_user_account_updatable && (
              <Dialog>
                <DialogTrigger>
                  <Button
                    variant="outline"
                    size="sm"
                    disabled={
                      isDeleteEmergencyContactPending ||
                      isUpdateEmergencyContactPending
                    }
                  >
                    <Trash2Icon className="mr-1 h-4 w-4" />
                    緊急連絡先をクリア
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>緊急連絡先をクリアする</DialogTitle>
                  </DialogHeader>
                  <DialogFooter>
                    <Button
                      variant="destructive"
                      onClick={() => handleDeleteEmergencyContact()}
                      disabled={isDeleteEmergencyContactPending}
                    >
                      クリアする
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            )}
          </CardTitle>
        </CardHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <div className="grid gap-4">
              <div className="grid gap-2">
                <Label htmlFor="emergency-name">氏名</Label>
                <Input
                  disabled={loading}
                  {...register("secure_name", {
                    required: "氏名を入力してください",
                    minLength: { value: 2, message: "入力値が短すぎます" },
                    maxLength: { value: 100, message: "入力値が長すぎます" },
                  })}
                  id="emergency-name"
                  type="text"
                  placeholder="田中 太郎"
                />
                <p className="text-sm text-red-400">
                  {errors.secure_name?.message}
                </p>
              </div>
              <div className="grid gap-2">
                <Label htmlFor="emergency-tel">電話番号</Label>
                <Input
                  disabled={loading}
                  {...register("secure_phone", {
                    required: "電話番号を入力してください",
                    minLength: { value: 2, message: "入力値が短すぎます" },
                    maxLength: { value: 100, message: "入力値が長すぎます" },
                  })}
                  id="emergency-tel"
                  type="tel"
                  placeholder="000-000-0000"
                />
                <p className="text-sm text-red-400">
                  {errors.secure_phone?.message}
                </p>
              </div>
              <div className="grid gap-2">
                <Label htmlFor="emergency-relationship">参加者との関係</Label>
                <Input
                  disabled={loading}
                  {...register("secure_relationship", {
                    required: "参加者との関係を入力してください",
                    minLength: { value: 1, message: "入力値が短すぎます" },
                    maxLength: { value: 100, message: "入力値が長すぎます" },
                  })}
                  id="emergency-relationship"
                  type="text"
                  placeholder="〇〇の父"
                />
                <p className="text-sm text-red-400">
                  {errors.secure_relationship?.message}
                </p>
              </div>
            </div>
          </CardContent>
          <CardFooter>
            {appConfig.flags.is_user_account_updatable ? (
              <>
                <Button variant="default" type="submit" disabled={loading}>
                  <SaveIcon className="mr-2 h-4 w-4" />
                  {isUpdateEmergencyContactPending ? "保存中..." : "保存"}
                </Button>
                {updateEmergencyContactError && (
                  <p className="text-sm text-red-400">
                    保存に失敗しました。運営にお問い合わせください。
                  </p>
                )}
              </>
            ) : (
              <Alert variant="destructive">変更受付期間外です。</Alert>
            )}
          </CardFooter>
        </form>
      </Card>
    </>
  );
};
