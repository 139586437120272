import { appConfigAtom, isAuthAdminAtom, isAuthStaffAtom } from "@/atoms";
import { Alert } from "@/components/ui/alert";
import { buttonVariants } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useAtomValue } from "jotai";
import { PlusIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { UserConfirmedReservationList } from "./UserConfirmedReservationList";
import { UserReservationRequestList } from "./UserReservationRequestList";

type UserReservationListCardProps = {
  userId: string;
};
export const UserReservationListCard = ({
  userId,
}: UserReservationListCardProps) => {
  const isAuthAdmin = useAtomValue(isAuthAdminAtom);
  const isAuthStaff = useAtomValue(isAuthStaffAtom);

  const displayHiddenReservations = isAuthAdmin || isAuthStaff;

  const appConfig = useAtomValue(appConfigAtom);
  if (!appConfig) return <>読み込み中...</>;
  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center justify-between">
          セッション
          {(appConfig.flags.is_confirmed_reservation_updatable ||
            appConfig.flags.is_reservation_request_updatable) && (
            <Link
              to={`/event_sessions`}
              className={buttonVariants({ size: "sm" })}
            >
              <PlusIcon className="mr-2 h-4 w-4" />
              一覧から追加登録
            </Link>
          )}
        </CardTitle>
      </CardHeader>
      <CardContent className="">
        <Tabs
          defaultValue={
            appConfig.flags.display_confirmed_reservation
              ? "confirmed_reservations"
              : "reservation_requests"
          }
        >
          <TabsList className="mb-8 grid w-full grid-cols-2">
            <TabsTrigger value="confirmed_reservations">
              確定済みの参加予約
            </TabsTrigger>
            <TabsTrigger value="reservation_requests">参加希望</TabsTrigger>
          </TabsList>
          <TabsContent value="confirmed_reservations" className="grid gap-8">
            {appConfig.flags.display_confirmed_reservation ? (
              <UserConfirmedReservationList userId={userId} />
            ) : (
              <>
                <Alert variant="default">
                  参加希望の抽選後に確定済みの予約を表示できるようになります。
                </Alert>
                {displayHiddenReservations && (
                  <>
                    <p className="text-green-600">運営向けにのみ表示中</p>
                    <UserConfirmedReservationList userId={userId} />
                  </>
                )}
              </>
            )}
          </TabsContent>
          <TabsContent value="reservation_requests" className="grid gap-8">
            {appConfig.flags.display_reservation_request ? (
              <UserReservationRequestList userId={userId} />
            ) : (
              <>
                <Alert variant="default">
                  参加希望の表示期間は終了しました。
                </Alert>
                {displayHiddenReservations && (
                  <>
                    <p className="text-green-600">運営向けにのみ表示中</p>
                    <UserReservationRequestList userId={userId} />
                  </>
                )}
              </>
            )}
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
};
