import {
    EventSessionEligible,
    ParticipantCategory,
    ParticipantList,
    UserAccount,
} from "@/generatedApi/eventManagerApi";

export const getParticipantEligible = (
  eventSessionEligible: EventSessionEligible,
  participantList?: ParticipantList | undefined | null
): boolean | undefined => {
  if (!participantList) {
    return undefined;
  }
  const participantCategories = participantList.items.map(
    (participant) => participant.participant_category
  );
  switch (eventSessionEligible) {
    case EventSessionEligible.ALLOWED:
      return true;
    case EventSessionEligible.NOT_ALLOWED:
      return false;
    case EventSessionEligible.ALLOWED_WITH_ELEMENTARY_SCHOOL:
      return (
        participantCategories.includes(
          ParticipantCategory.ELEMENTARY_SCHOOL_LOWER
        ) ||
        participantCategories.includes(
          ParticipantCategory.ELEMENTARY_SCHOOL_MIDDLE
        ) ||
        participantCategories.includes(
          ParticipantCategory.ELEMENTARY_SCHOOL_UPPER
        )
      );
    case EventSessionEligible.ALLOWED_WITH_ELEMENTARY_OR_JUNIOR_HIGH_SCHOOL:
      return (
        participantCategories.includes(
          ParticipantCategory.ELEMENTARY_SCHOOL_LOWER
        ) ||
        participantCategories.includes(
          ParticipantCategory.ELEMENTARY_SCHOOL_MIDDLE
        ) ||
        participantCategories.includes(
          ParticipantCategory.ELEMENTARY_SCHOOL_UPPER
        ) ||
        participantCategories.includes(ParticipantCategory.JUNIOR_HIGH_SCHOOL)
      );
    default:
      return false;
  }
};

export const getParticipantEligibleList = (
  eventSessionEligibles: Record<string, EventSessionEligible>,
  userAccount: UserAccount
): { [key: string]: boolean | undefined } => {
  const result: { [key: string]: boolean | undefined } = {};
  Object.entries(eventSessionEligibles).forEach(([key, value]) => {
    result[key] = getParticipantEligible(value, userAccount?.participant_list);
  });
  return result;
};
