import { ColumnDef } from "@tanstack/react-table";
import { Link } from "react-router-dom";

export type UserAccountRow = {
  user_id: string;
  user_number: number;
  name: string;
  participant_count: string;
};

export const columns: ColumnDef<UserAccountRow>[] = [
  {
    accessorKey: "user_number",
    header: "#",
  },
  {
    accessorKey: "name",
    header: "名前",
    cell: ({ row }) => {
      return (
        <Link to={`/users/${row.original.user_id}/reservations`} className="text-blue-600 underline">
          {row.original.name}
        </Link>
      );
    },
  },
  {
    accessorKey: "participant_count",
    header: "参加人数",
  },
];
