import { useFetcherWithSwr } from "@/api";
import { EventSession, UserAccount } from "@/generatedApi/eventManagerApi";
import { CapacityDisplayBase } from ".";

type ConfirmedReservationCapacityDisplayProps = {
  eventSession: EventSession;
  userAccount?: UserAccount;
};
export const ConfirmedReservationCapacityDisplay = ({
  eventSession,
  userAccount,
}: ConfirmedReservationCapacityDisplayProps) => {
  const { data: participantCount } = useFetcherWithSwr({
    apiName: "event_manager",
    serviceName: "eventSessions",
    operationId: "getEventSessionConfirmedReservationParticipantCount",
    requestParameters: { eventSessionId: eventSession.event_session_id },
  });
  const { data: reservationRequestCapacityFilledRate } = useFetcherWithSwr({
    apiName: "event_manager",
    serviceName: "eventSessions",
    operationId: "getEventSessionConfirmedReservationCapacityFilledRate",
    requestParameters: {
      eventSessionId: eventSession.event_session_id,
      userId: userAccount?.user_id,
    },
  });

  return (
    <CapacityDisplayBase
      capacityFilledRate={reservationRequestCapacityFilledRate}
      participantCount={participantCount}
      maxCapacity={eventSession.max_capacity}
      capacitySuffix="名が参加確定済"
    />
  );
};
