export function getJstFromUnixtime(unixTimestamp: number | undefined): string {
  if (unixTimestamp === undefined) {
    return "";
  }

  const date = new Date(unixTimestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  const japaneseDateTime = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
  return japaneseDateTime;
}

export function getJstDateFromUnixtime(unixTimestamp: number | undefined): string {
  if (unixTimestamp === undefined) {
    return "";
  }

  const date = new Date(unixTimestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  const japaneseDateTime = `${year}/${month}/${day}`;
  return japaneseDateTime;
}


export const getCapacityFilledRateLabel = (
  capacityFilledRate: number
): string => {
  if (capacityFilledRate < 0) {
    return "予約不可";
  }
  if (capacityFilledRate >= 10000) {
    return "満員";
  }
  if (capacityFilledRate >= 9000) {
    return "ほぼ満員";
  }
  if (capacityFilledRate >= 8000) {
    return "残りわずか";
  }
  if (capacityFilledRate >= 1000) {
    return "空きあり";
  }
  return "空席";
};
export function objectToQueryString(params: { [key: string]: string }): string {
  const queryString = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");
  return queryString;
}
