import { useFetcherWithSwr } from "@/api";
import { appConfigAtom } from "@/atoms";
import { buttonVariants } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useAtomValue } from "jotai";
import { Helmet } from "react-helmet";

import { Alert } from "@/components/ui/alert";
import { ChevronRight } from "lucide-react";
import { Link } from "react-router-dom";

export const UnauthTopPage = () => {
  const disableRegister = false;
  const { data: version, error } = useFetcherWithSwr({
    apiName: "event_manager",
    serviceName: "default",
    operationId: "getVersion",
    requestParameters: {},
  });
  const appConfig = useAtomValue(appConfigAtom);
  if (!appConfig) return <>読み込み中...</>;
  return (
    <div className="mx-auto max-w-md p-4">
      <Helmet>
        <title>参加登録</title>
      </Helmet>
      <Card className="">
        <CardHeader>
          <CardTitle className="text-2xl">
            <img src="/logo.png" alt="logo" className="mb-2 block w-52" />
            {appConfig?.displays.event_short_name ?? ""}
            <br />
            参加申し込みサイト
          </CardTitle>
        </CardHeader>
        <CardContent className="grid gap-4">
          {appConfig.flags.is_registration_open && (
            <>
              <div>
                <h2 className="mb-2 text-lg">個人情報の取り扱いについて</h2>
                <ol className="mb-2 list-inside list-decimal space-y-2 text-sm">
                  <li>
                    登録された氏名、メールアドレスその他の個人情報は、本イベントに関連する業務（参加登録、参加者の抽選、実施のお知らせ等、当日の受付）を行うために利用します。
                  </li>
                  <li>
                    登録された氏名、メールアドレスその他の個人情報は、集計され、個人が特定されない形(例えば参加者統計など)でイベントの報告書などに掲載されることがあります。
                  </li>
                  <li>
                    登録された氏名、メールアドレスその他の個人情報は、上記（1）、（2）の業務を行うこと以外のために使用し、また第三者に提供することはありません。
                  </li>
                  <li>
                    本イベントの参加申し込み受付業務は名古屋大学様から弊社（株式会社Hashup）が受託しています。名古屋大学様と弊社の間で個人情報の取扱いが適切に行われる旨、契約を結んでおります。弊社はイベント終了後、名古屋大学様に最終データを提出後、本件に関係するすべての個人情報を削除いたします。
                  </li>
                </ol>
                <p className="mb-2 text-sm">
                  詳細については
                  <Link to="/privacy" className="text-blue-500">
                    プライバシーポリシー
                  </Link>
                  をご覧ください。
                </p>
              </div>
              <p>個人情報の取り扱いに同意して</p>
              {!disableRegister && (
                <Link
                  to="/signup"
                  className={buttonVariants({ variant: "default", size: "lg" })}
                >
                  参加登録に進む
                  <ChevronRight className="ml-1 h-4 w-4" />
                </Link>
              )}
            </>
          )}
          {!appConfig.flags.is_registration_open && (
            <Alert variant="default">参加登録は締め切られました。</Alert>
          )}
          <p>既に登録がお済みの方は</p>
          <Link
            to="/signin"
            className={buttonVariants({ variant: "secondary", size: "lg" })}
          >
            ログインに進む
            <ChevronRight className="ml-1 h-4 w-4" />
          </Link>

          {disableRegister && (
            <p className="text-sm text-red-500">
              新規参加登録は締め切られました。
            </p>
          )}
        </CardContent>
      </Card>
      <div className="mt-4 grid gap-1 text-center text-xs text-muted-foreground/50">
        <p>
          <Link to="/privacy">プライバシーポリシー</Link>
        </p>
        <p>{appConfig?.displays.event_full_name}</p>
        <p>
          技術協力:
          <a
            href={appConfig?.displays.hosting_organization_url}
            target="_blank"
          >
            {appConfig?.displays.hosting_organization}
          </a>
        </p>
        <p>
          {error ? (
            <span className="text-red-300">エラー</span>
          ) : (
            <>ver. {version ? version : "loading..."}</>
          )}
        </p>
      </div>
    </div>
  );
};
