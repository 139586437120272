import { buttonVariants } from "@/components/ui/button";
import { ChevronLeft } from "lucide-react";
import { Helmet } from "react-helmet";

import { useFetcherWithSwr, useSender } from "@/api";
import { appConfigAtom } from "@/atoms";
import { useTimeTableMap, useUserId } from "@/hooks";
import { useAtomValue } from "jotai";
import { useCallback, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { EventSessionSummaryCard } from "./EventSessionSummaryCard";

export const EventSessionListPage = () => {
  const userId = useUserId();
  ///

  const { data: eventSessionList } = useFetcherWithSwr({
    apiName: "event_manager",
    serviceName: "eventSessions",
    operationId: "getEventSessionList",
    requestParameters: {},
  });
  const sortedEventSessionList = useMemo(
    () =>
      eventSessionList
        ?.filter((e) => !e.event_session_id.startsWith("9"))
        .sort(
          (a, b) => Number(a.event_session_id) - Number(b.event_session_id)
        ),
    [eventSessionList]
  );
  const {
    data: confirmedReservationList,
    sender: getConfirmedReservationListByUserId,
  } = useSender({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "getConfirmedReservationListByUserId",
  });

  ///

  const {
    data: reservationRequestList,
    sender: getReservationRequestListByUserId,
  } = useSender({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "getReservationRequestListByUserId",
  });
  const handleFetchUserData = useCallback(async () => {
    if (!userId) return;
    await getConfirmedReservationListByUserId({ userId });
    await getReservationRequestListByUserId({ userId });
  }, [
    getReservationRequestListByUserId,
    userId,
    getConfirmedReservationListByUserId,
  ]);

  useEffect(() => {
    handleFetchUserData();
  }, [handleFetchUserData]);

  const appConfig = useAtomValue(appConfigAtom);

  const timeTableMap = useTimeTableMap();

  if (!appConfig) return null;

  return (
    <>
      <Helmet>
        <title>セッション一覧</title>
      </Helmet>
      <div className="mb-8">
        <Link
          className={buttonVariants({ variant: "secondary", size: "sm" })}
          to={"/"}
        >
          <ChevronLeft className="mr-1 h-4 w-4" />
          {"トップページへ戻る"}
        </Link>
      </div>
      <div className="mb-4 grid gap-4">
        <h2 className="text-2xl font-semibold tracking-tight">
          セッション一覧
        </h2>
        {[1, 2, 3, 4, 5].map((timeSlot) => (
          <div className="mb-8 space-y-2" key={`S${timeSlot}`}>
            <div className="mb-4 flex gap-0.5">
              <div className="bg-slate-600 px-2 text-white">
                {timeSlot}時間目
              </div>
              <div className="rounded-br-sm rounded-tr-sm bg-slate-600 px-2 text-white">
                {timeTableMap[timeSlot]}
              </div>
            </div>
            {sortedEventSessionList
              ?.filter((eventSession) => eventSession.time_slot === timeSlot)
              .map((eventSession) => (
                <EventSessionSummaryCard
                  eventSession={eventSession}
                  reservationRequestList={reservationRequestList}
                  confirmedReservationList={confirmedReservationList}
                  key={eventSession.event_session_id}
                />
              ))}
          </div>
        ))}
      </div>
    </>
  );
};
