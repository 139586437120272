import { useFetcherWithSwr } from "@/api";
import { currentAuthUserAtom, isAuthAdminAtom, isAuthStaffAtom } from "@/atoms";
import { useAtomValue } from "jotai";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "./DataTable";
import { UserAccountRow, columns } from "./columns";

export const UserListPage = () => {
  const currentAuthUser = useAtomValue(currentAuthUserAtom);
  const currentUserId = currentAuthUser?.userId;
  const isAuthAdmin = useAtomValue(isAuthAdminAtom);
  const isAuthStaff = useAtomValue(isAuthStaffAtom);

  const { data: userAccountList } = useFetcherWithSwr({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "getUserAccountList",
    requestParameters: {},
    swrConfiguration: { errorRetryCount: 1 },
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (!(isAuthAdmin || isAuthStaff)) {
      navigate(`/users/${currentUserId}`);
    }
  }, [isAuthAdmin, isAuthStaff, currentUserId, navigate]);

  const userAccountRow: UserAccountRow[] = useMemo(() => {
    return (
      userAccountList?.items
        .map((item) => {
          return {
            user_id: item.user_id,
            user_number: item.user_number,
            name: item.applicant?.name ?? "未登録",
            participant_count: item.participant_list?.items.length
              ? `${item.participant_list?.items.length}名`
              : "未登録",
          };
        })
        .sort((a, b) => a.user_number - b.user_number) ?? []
    );
  }, [userAccountList]);

  if (isAuthAdmin || isAuthStaff) {
    return (
      <div>
        <div className="mb-4 grid gap-4">
          <h2 className="text-2xl font-semibold tracking-tight">ユーザ一覧</h2>
        </div>
        <DataTable data={userAccountRow} columns={columns} />
      </div>
    );
  } else {
    return (
      <div>
        <h1>Not Found</h1>
      </div>
    );
  }
};
