import { useFetcherWithSwr, useSender } from "@/api";
import { appConfigAtom } from "@/atoms";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button, buttonVariants } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  EventSession,
  EventSessionRequestType,
  ReservationRequest,
  UserAccount,
} from "@/generatedApi/eventManagerApi";
import { AutonomousEventSessionSummaryCard } from "@/pages/EventSessionListPage/EventSessionSummaryCard/AutonomousEventSessionSummaryCard";
import { getParticipantEligibleList } from "@/pages/common";
import { useAtomValue } from "jotai";
import {
  AlertCircle,
  ChevronRightIcon,
  PlusIcon,
  Trash2Icon,
} from "lucide-react";
import { useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";

type BodyProps = {
  eventSession: EventSession;
  userAccount: UserAccount;
  reservationRequestList: ReservationRequest[];
  reloadUserData: () => void;
};
const Body = ({
  eventSession,
  userAccount,
  reservationRequestList,
}: BodyProps) => {
  const navigate = useNavigate();
  const {
    sender: createReservationRequest,
    isLoading: isCreateReservationRequestPending,
  } = useSender({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "createReservationRequest",
  });

  const handleCreateRequestOnlyReservationRequest = useCallback(async () => {
    const res = await createReservationRequest({
      userId: userAccount.user_id,
      requestBody: {
        event_session_id: eventSession.event_session_id,
        request_type: eventSession.event_session_request_type,
        user_id: userAccount.user_id,
      },
    });
    if (res) {
      toast.success("参加希望を登録しました");
      navigate(`/users/${userAccount.user_id}/reservations`);
    } else {
      toast.error("参加希望の登録に失敗しました");
    }
  }, [createReservationRequest, userAccount, eventSession, navigate]);
  const handleCreateOrderedRequestReservationRequest = useCallback(
    async (order: number) => {
      const res = await createReservationRequest({
        userId: userAccount.user_id,
        requestBody: {
          event_session_id: eventSession.event_session_id,
          request_type: eventSession.event_session_request_type,
          user_id: userAccount.user_id,
          request_order: order,
        },
      });
      if (res) {
        toast.success(`第${order}希望に登録しました`);
        navigate(`/users/${userAccount.user_id}/reservations`);
      } else {
        toast.error(`第${order}希望の登録に失敗しました`);
      }
    },
    [createReservationRequest, userAccount, eventSession, navigate]
  );

  ///

  const {
    sender: deleteReservationRequest,
    isLoading: isDeleteReservationRequestPending,
  } = useSender({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "deleteReservationRequest",
  });
  const handleDeleteReservationRequest = useCallback(async () => {
    const reservationId = reservationRequestList.find(
      (reservationRequest) =>
        reservationRequest.event_session_id === eventSession.event_session_id
    )?.reservation_id;
    if (!reservationId) {
      toast.error("参加希望の取り消しに失敗しました");
      return;
    }
    const res = await deleteReservationRequest({
      userId: userAccount.user_id,
      reservationId,
    });
    if (res) {
      toast.success("参加希望を取り消しました");
      navigate(`/users/${userAccount.user_id}/reservations`);
    } else {
      toast.error("参加希望の取り消しに失敗しました");
    }
  }, [
    deleteReservationRequest,
    userAccount,
    eventSession,
    reservationRequestList,
    navigate,
  ]);

  ///

  const isCurrentSessionRequested = useMemo(() => {
    const res = reservationRequestList.some(
      (reservationRequest) =>
        reservationRequest.event_session_id === eventSession.event_session_id
    );
    return res;
  }, [reservationRequestList, eventSession]);

  const currentSessionRequestOrder = useMemo(() => {
    const res = reservationRequestList.find(
      (reservationRequest) =>
        reservationRequest.event_session_id === eventSession.event_session_id
    )?.request_order;
    return res;
  }, [reservationRequestList, eventSession]);

  const isCurrentTimeSlotRequested = useMemo(
    () =>
      reservationRequestList
        .filter(
          (reservationRequest) =>
            reservationRequest.request_type ===
            EventSessionRequestType.REQUEST_ONLY
        )
        .some(
          (reservationRequest) =>
            reservationRequest.time_slot === eventSession.time_slot
        ),
    [reservationRequestList, eventSession]
  );
  const conflictingEventSessionId = useMemo(
    () =>
      reservationRequestList.find(
        (reservationRequest) =>
          reservationRequest.time_slot === eventSession.time_slot &&
          reservationRequest.event_session_id !==
            eventSession.event_session_id &&
          reservationRequest.request_type ===
            EventSessionRequestType.REQUEST_ONLY &&
          eventSession.event_session_request_type ===
            EventSessionRequestType.REQUEST_ONLY
      )?.event_session_id,
    [reservationRequestList, eventSession]
  );

  ///

  const reservationEligible = useMemo(() => {
    if (
      !userAccount.participant_list ||
      userAccount.participant_list.items.length === 0
    ) {
      return undefined;
    }
    const res = getParticipantEligibleList(eventSession.eligibles, userAccount);
    const filtered = userAccount.participant_list?.items.map(
      (participant) => res[participant.participant_category]
    );
    return Object.values(filtered).every((v) => v);
  }, [userAccount, eventSession]);

  const orderedReservationRequest = useMemo(() => {
    const res: { [order: number]: ReservationRequest } = {};
    reservationRequestList.forEach((reservationRequest) => {
      if (reservationRequest.request_order)
        res[reservationRequest.request_order] = reservationRequest;
    });
    return res;
  }, [reservationRequestList]);

  ///

  const { data: capacityFilledRate } = useFetcherWithSwr({
    apiName: "event_manager",
    serviceName: "eventSessions",
    operationId: "getEventSessionReservationRequestCapacityFilledRate",
    requestParameters: {
      userId: userAccount.user_id,
      eventSessionId: eventSession.event_session_id,
    },
  });

  const isCapacityFilled = useMemo(() => {
    if (!capacityFilledRate) {
      return undefined;
    }
    return capacityFilledRate > 10000;
  }, [capacityFilledRate]);

  const appConfig = useAtomValue(appConfigAtom);
  if (!appConfig) return <>読み込み中...</>;
  return (
    <div className="grid gap-4">
      {eventSession.event_session_request_type ===
        EventSessionRequestType.ORDERED_REQUEST &&
        isCapacityFilled && (
          <Alert>
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>
              セッションは定員以上の申し込みがありますので、抽選となります。
            </AlertDescription>
          </Alert>
        )}
      {!isCurrentSessionRequested &&
        isCapacityFilled &&
        eventSession.event_session_request_type ===
          EventSessionRequestType.REQUEST_ONLY && (
          <Alert>
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>
              セッションは満員のため、参加希望を登録できません。
              <br />
              抽選後に空きがある場合、参加希望を登録できるようになります。
            </AlertDescription>
          </Alert>
        )}
      {!isCapacityFilled && !reservationEligible && (
        <Alert>
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>
            セッションの参加要件にを満たしていないため、参加希望を登録できません。
          </AlertDescription>
        </Alert>
      )}
      {!isCapacityFilled &&
        !isCurrentTimeSlotRequested &&
        !isCurrentSessionRequested &&
        reservationEligible &&
        eventSession.event_session_request_type ===
          EventSessionRequestType.REQUEST_ONLY &&
        appConfig.flags.is_reservation_request_updatable && (
          <>
            <Button
              onClick={handleCreateRequestOnlyReservationRequest}
              disabled={isCreateReservationRequestPending}
            >
              <PlusIcon className="mr-1 h-4 w-4" />
              {isCreateReservationRequestPending
                ? "登録中"
                : "参加希望を登録する"}
            </Button>
          </>
        )}
      {reservationEligible &&
        eventSession.event_session_request_type ===
          EventSessionRequestType.ORDERED_REQUEST &&
        !isCurrentSessionRequested &&
        appConfig.flags.is_reservation_request_updatable && (
          <div className="grid gap-4">
            {Array.from(
              { length: appConfig?.params.max_reservation_request_count },
              (_, i) => i + 1
            ).map((order) => (
              <div key={order}>
                <div className="grid gap-1">
                  <div className="text-sm text-muted-foreground">
                    第{order}希望
                  </div>
                  {orderedReservationRequest[order] ? (
                    <>
                      <p>下記のセッションを希望中です</p>
                      <div className="-mx-3">
                        <AutonomousEventSessionSummaryCard
                          eventSessionId={
                            orderedReservationRequest[order].event_session_id
                          }
                          reservationRequestList={reservationRequestList}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="">
                      <Button
                        variant="default"
                        onClick={() =>
                          handleCreateOrderedRequestReservationRequest(order)
                        }
                        disabled={isCreateReservationRequestPending}
                      >
                        <PlusIcon className="mr-2 h-4 w-4" />
                        {isCreateReservationRequestPending ? (
                          "処理中..."
                        ) : (
                          <>
                            第{order}
                            希望に登録する
                          </>
                        )}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      {!isCapacityFilled && conflictingEventSessionId && (
        <div className="grid gap-4">
          <p>
            同じ時間で下記のセッションを登録済みです。
            登録を取り消してから再度登録してください。
          </p>
          <div className="-mx-0">
            <AutonomousEventSessionSummaryCard
              eventSessionId={conflictingEventSessionId}
              reservationRequestList={reservationRequestList}
              link={false}
            />
          </div>
        </div>
      )}
      {isCurrentSessionRequested && (
        <div className="grid gap-4">
          <p>
            {currentSessionRequestOrder
              ? `第${currentSessionRequestOrder}希望に登録済みです`
              : "参加希望に登録済みです"}
          </p>
          <div>
            <Button
              variant="outline"
              onClick={handleDeleteReservationRequest}
              disabled={isDeleteReservationRequestPending}
            >
              <Trash2Icon className="mr-2 h-4 w-4" />
              {isDeleteReservationRequestPending
                ? "取り消し中..."
                : "参加希望を取り消す"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

type EventSessionSingleReservationRequestCardProps = {
  eventSession: EventSession;
  userAccount?: UserAccount;
  reservationRequestList: ReservationRequest[];
  reloadUserData: () => void;
};
export const EventSessionSingleReservationRequestCard = ({
  eventSession,
  userAccount,
  reservationRequestList,
  reloadUserData,
}: EventSessionSingleReservationRequestCardProps) => {
  const appConfig = useAtomValue(appConfigAtom);
  if (!appConfig) return <>読み込み中...</>;
  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center justify-between">
            参加希望登録
            {userAccount && (
              <Link
                to={`/users/${userAccount.user_id}/reservations`}
                className={buttonVariants({ size: "sm", variant: "secondary" })}
              >
                登録内容一覧
                <ChevronRightIcon className="ml-1 h-4 w-4" />
              </Link>
            )}
          </CardTitle>
        </CardHeader>
        <CardContent>
          {appConfig.flags.is_reservation_request_updatable ? (
            <>
              {!userAccount ||
              !userAccount.participant_list ||
              userAccount.participant_list.items.length < 1 ? (
                <div>
                  参加者情報を登録すると、セッションの参加希望登録ができるようになります。
                </div>
              ) : (
                <>
                  <Body
                    {...{
                      eventSession,
                      userAccount,
                      reservationRequestList,
                      reloadUserData,
                    }}
                  />
                </>
              )}
            </>
          ) : (
            <Alert variant="destructive">
              参加希望変更受付期間外です。抽選後に追加・変更できるようになります。
            </Alert>
          )}
        </CardContent>
      </Card>
    </>
  );
};
