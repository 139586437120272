import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { eventSessionEligibleMap, participantCategoryMap } from "@/constants";
import {
  EventSession,
  ParticipantCategory,
  UserAccount,
} from "@/generatedApi/eventManagerApi";
import { getParticipantEligibleList } from "@/pages/common";
import { BanIcon, CircleCheckBigIcon } from "lucide-react";
import { useMemo } from "react";

type EventSessionSingleEligibleCardProps = {
  eventSession: EventSession;
  userAccount?: UserAccount;
};
export const EventSessionSingleEligibleCard = ({
  eventSession,
  userAccount,
}: EventSessionSingleEligibleCardProps) => {
  const participantCategories =
    userAccount?.participant_list?.items.map(
      (participant) => participant.participant_category
    ) ?? [];

  const participantEligibles = useMemo(() => {
    if (!userAccount) {
      return undefined;
    }
    return getParticipantEligibleList(eventSession.eligibles, userAccount);
  }, [userAccount, eventSession]);
  return (
    <Card>
      <CardHeader>
        <CardTitle>参加要件</CardTitle>
      </CardHeader>
      <CardContent className="grid gap-3">
        {Object.entries(participantCategoryMap).map(
          ([participantCategory, label]) => (
            <div
              key={participantCategory}
              className={`flex flex-wrap items-center justify-between text-wrap ${
                participantCategories.length > 0 &&
                (participantCategories.includes(
                  participantCategory as ParticipantCategory
                )
                  ? ""
                  : "opacity-30")
              }`}
            >
              <div className="">{label}</div>
              <div
                className={`
                  flex flex-grow items-center justify-end
                  ${
                    participantEligibles &&
                    participantEligibles[participantCategory] !== undefined &&
                    participantCategories.includes(
                      participantCategory as ParticipantCategory
                    )
                      ? participantEligibles[participantCategory]
                        ? "text-green-600"
                        : "text-red-600"
                      : ""
                  }
                  `}
              >
                {participantEligibles &&
                participantEligibles[participantCategory] !== undefined &&
                participantCategories.includes(
                  participantCategory as ParticipantCategory
                ) ? (
                  participantEligibles[participantCategory] ? (
                    <CircleCheckBigIcon className="h-4 w-4 flex-shrink-0" />
                  ) : (
                    <BanIcon className="h-4 w-4 flex-shrink-0" />
                  )
                ) : (
                  <></>
                )}
                {
                  eventSessionEligibleMap[
                    eventSession.eligibles[participantCategory]
                  ]
                }
              </div>
            </div>
          )
        )}
      </CardContent>
    </Card>
  );
};
