import { fetchAuthSession } from "aws-amplify/auth";
import { useAtomValue } from "jotai";
import { useCallback, useEffect, useState } from "react";
import { appConfigAtom } from "./atoms";

export const useUserId = () => {
  const [userId, setUserId] = useState<string | undefined>();

  const getAuth = useCallback(async () => {
    setUserId(undefined);
    try {
      const res = await fetchAuthSession();
      setUserId(res.userSub);
    } catch (e) {
      setUserId(undefined);
    }
  }, []);
  useEffect(() => {
    getAuth();
  }, [getAuth]);

  return userId;
};

export const useSecureEmail = () => {
  const [email, setEmail] = useState<string | undefined>();

  const getAuth = useCallback(async () => {
    setEmail(undefined);
    try {
      const auth = await fetchAuthSession();
      const email = auth.tokens?.idToken?.payload.email as string;
      setEmail(email);
    } catch (e) {
      setEmail(undefined);
    }
  }, []);
  useEffect(() => {
    getAuth();
  }, [getAuth]);

  return email;
};

export const useTimeTableMap = () => {
  const appConfig = useAtomValue(appConfigAtom);
  const [timeTableMap, setTimeTableMap] = useState<Record<number, string>>({});
  useEffect(() => {
    if (!appConfig) {
      setTimeTableMap({ 1: "", 2: "", 3: "", 4: "", 5: "" });
      return
    }
    setTimeTableMap({
      1: appConfig.displays.slot_1_time,
      2: appConfig.displays.slot_2_time,
      3: appConfig.displays.slot_3_time,
      4: appConfig.displays.slot_4_time,
      5: appConfig.displays.slot_5_time,
    } as { [key: number]: string });
  }, [appConfig]);
  return timeTableMap;
};
