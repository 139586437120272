import { useSender } from "@/api";
import { useUserId } from "@/hooks";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { EventSessionCard } from "./EventSessionCard";
import { UserAccountCard } from "./UserAccountCard";
import { UserReservationCard } from "./UserReservationCard";

export const TopPage = () => {
  const userId = useUserId();

  const {
    data: secureUserAccount,
    isLoading: isGetSecureUserAccountPending,
    sender: getSecureUserAccount,
  } = useSender({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "getSecureUserAccount",
  });
  useEffect(() => {
    if (userId) {
      getSecureUserAccount({ userId });
    }
  }, [userId, getSecureUserAccount]);

  return (
    <div className="grid gap-4">
      <Helmet>
        <title>トップ</title>
      </Helmet>
      {!userId || isGetSecureUserAccountPending ? (
        <>読み込み中...</>
      ) : (
        <>
          <UserAccountCard
            userId={userId}
            secureUserAccount={secureUserAccount}
          />
          <EventSessionCard />
          <UserReservationCard userId={userId} />
        </>
      )}
    </div>
  );
};
