/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ParticipantCategory {
    ELEMENTARY_SCHOOL_LOWER = 'elementary_school_lower',
    ELEMENTARY_SCHOOL_MIDDLE = 'elementary_school_middle',
    ELEMENTARY_SCHOOL_UPPER = 'elementary_school_upper',
    JUNIOR_HIGH_SCHOOL = 'junior_high_school',
    HIGH_SCHOOL = 'high_school',
    HIGHER_EDUCATION = 'higher_education',
    ADULT = 'adult',
}
