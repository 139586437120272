import { Button, buttonVariants } from "@/components/ui/button";
import { ChevronLeft, ChevronRightIcon } from "lucide-react";
import { Helmet } from "react-helmet";

import { useFetcherWithSwr, useSender } from "@/api";
import {
  appConfigAtom,
  currentAuthUserAtom
} from "@/atoms";
import { Badge } from "@/components/ui/badge";
import { EventSessionRequestType } from "@/generatedApi/eventManagerApi";
import { useAtomValue } from "jotai";
import { useCallback, useEffect, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ConfirmedReservationCapacityDisplay } from "./CapacityDisplay/ConfirmedReservationCapacityDisplay";
import { ReservationRequestCapacityDisplay } from "./CapacityDisplay/ReservationRequestCapacityDisplay";
import { EventSessionSingleConfirmedReservationCard } from "./EventSessionSingleConfirmedReservationCard";
import { EventSessionSingleDetailCard } from "./EventSessionSingleDetailCard";
import { EventSessionSingleEligibleCard } from "./EventSessionSingleEligibleCard";
import { EventSessionSingleRecommendationCard } from "./EventSessionSingleRecommendationCard";
import { EventSessionSingleReservationRequestCard } from "./EventSessionSingleReservationRequestCard";

export const EventSessionSinglePage = () => {
  const currentUser = useAtomValue(currentAuthUserAtom);
  const userId = useMemo(
    () => (!currentUser ? undefined : currentUser.userId),
    [currentUser]
  );
  const { eventSessionId } = useParams<{ eventSessionId: string }>();
  const { data: eventSession } = useFetcherWithSwr({
    apiName: "event_manager",
    serviceName: "eventSessions",
    operationId: "getEventSession",
    requestParameters: { eventSessionId: eventSessionId ?? "" },
  });

  const { data: userAccount, sender: getUserAccount } = useSender({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "getUserAccount",
  });
  const {
    data: reservationRequestList,
    sender: getReservationRequestListByUserId,
  } = useSender({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "getReservationRequestListByUserId",
  });
  const {
    data: confirmedReservationList,
    sender: getConfirmedReservationListByUserId,
  } = useSender({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "getConfirmedReservationListByUserId",
  });
  const handleFetchUserData = useCallback(async () => {
    if (!userId) return;
    await getUserAccount({ userId });
    await getReservationRequestListByUserId({ userId });
    await getConfirmedReservationListByUserId({ userId });
  }, [
    getUserAccount,
    getReservationRequestListByUserId,
    userId,
    getConfirmedReservationListByUserId,
  ]);

  useEffect(() => {
    handleFetchUserData();
  }, [eventSessionId, handleFetchUserData]);

  ///

  const { data: eventSessionList } = useFetcherWithSwr({
    apiName: "event_manager",
    serviceName: "eventSessions",
    operationId: "getEventSessionList",
    requestParameters: {},
  });
  const sortedEventSessionList = useMemo(
    () =>
      eventSessionList
        ?.filter((e) => !e.event_session_id.startsWith("9"))
        .sort(
          (a, b) => Number(a.event_session_id) - Number(b.event_session_id)
        ),
    [eventSessionList]
  );

  ///

  const currentIndex = useMemo(() => {
    return (
      sortedEventSessionList?.findIndex(
        (eventSession) => eventSession.event_session_id === eventSessionId
      ) ?? 0
    );
  }, [eventSessionId, sortedEventSessionList]);

  const nextEventSession = useMemo(() => {
    if (!sortedEventSessionList) {
      return null;
    }
    if (currentIndex === sortedEventSessionList?.length - 1) {
      return null;
    } else {
      return sortedEventSessionList?.[currentIndex + 1];
    }
  }, [currentIndex, sortedEventSessionList]);

  const prevEventSession = useMemo(() => {
    if (!sortedEventSessionList) {
      return null;
    }
    if (currentIndex === 0) {
      return null;
    } else {
      return sortedEventSessionList?.[currentIndex - 1];
    }
  }, [currentIndex, sortedEventSessionList]);

  const navigate = useNavigate();

  ///

  const isCurrentSessionRequested = useMemo(
    () =>
      reservationRequestList?.some(
        (reservationRequest) =>
          reservationRequest.event_session_id === eventSession?.event_session_id
      ),
    [reservationRequestList, eventSession]
  );

  const currentSessionRequestOrder = useMemo(() => {
    if (!reservationRequestList || !eventSession) {
      return undefined;
    }
    const currentSessionRequest = reservationRequestList.find(
      (reservationRequest) =>
        reservationRequest.event_session_id === eventSession.event_session_id
    );
    return currentSessionRequest?.request_order;
  }, [reservationRequestList, eventSession]);

  ///

  const isCurrentSessionConfirmed = useMemo(() => {
    return (
      confirmedReservationList?.some(
        (confirmedReservation) =>
          confirmedReservation.event_session_id ===
          eventSession?.event_session_id
      ) ?? false
    );
  }, [confirmedReservationList, eventSession]);


  const appConfig = useAtomValue(appConfigAtom);

  const displayReservationRequestCard = useMemo(() => {
    if (appConfig?.flags.display_confirmed_reservation){
      return false
    }
    if (appConfig?.flags.display_reservation_request) {
      return true;
    } else {
      return false;
    }
  }, [ appConfig]);

  const displayConfirmedReservationCard = useMemo(() => {
    if (appConfig?.flags.display_confirmed_reservation) {
      return true;
    } else {
      return false;
    }
  }, [appConfig]);

  return (
    <>
      <Helmet>
        <title>{eventSession?.name}</title>
      </Helmet>
      <div className="mb-8">
        <div className="flex items-center justify-between gap-1">
          <Link
            className={buttonVariants({ variant: "secondary", size: "sm" })}
            to={"/event_sessions"}
          >
            <ChevronLeft className="mr-1 h-4 w-4" />
            セッション一覧ページへ戻る
          </Link>
          <div className="flex items-center gap-2">
            <Button
              variant="ghost"
              size="icon"
              disabled={!prevEventSession}
              onClick={() =>
                navigate(
                  `/event_sessions/${prevEventSession?.event_session_id}`
                )
              }
            >
              <ChevronLeft className="h-4 w-4" />
            </Button>
            <Button
              variant="ghost"
              size="icon"
              disabled={!nextEventSession}
              onClick={() =>
                navigate(
                  `/event_sessions/${nextEventSession?.event_session_id}`
                )
              }
            >
              <ChevronRightIcon className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
      <div className="mb-4 grid gap-4">
        <div className="grid gap-2">
          <h2 className="text-2xl font-semibold tracking-tight">
            {eventSession?.name ?? "読み込み中..."}
          </h2>
          <div className="flex flex-grow-0 gap-1">
            {isCurrentSessionRequested &&
              (currentSessionRequestOrder ? (
                <Badge variant="default" className="bg-yellow-600">
                  第{currentSessionRequestOrder}希望中
                </Badge>
              ) : (
                <Badge variant="default" className="bg-yellow-600">
                  参加希望中
                </Badge>
              ))}
            {isCurrentSessionConfirmed && (
              <Badge variant="default" className="bg-green-600">
                参加確定済
              </Badge>
            )}
          </div>
        </div>
        {eventSession && (
          <>
            {displayReservationRequestCard && (
              <ReservationRequestCapacityDisplay
                eventSession={eventSession}
                userAccount={userAccount}
                showCapacityFilled={
                  eventSession.event_session_request_type ===
                  EventSessionRequestType.REQUEST_ONLY
                }
              />
            )}
            {displayConfirmedReservationCard && (
              <ConfirmedReservationCapacityDisplay
                eventSession={eventSession}
                userAccount={userAccount}
              />
            )}
            <EventSessionSingleDetailCard eventSession={eventSession} />
            <EventSessionSingleRecommendationCard
              eventSession={eventSession}
              userAccount={userAccount}
            />
            <EventSessionSingleEligibleCard
              eventSession={eventSession}
              userAccount={userAccount}
            />
            {displayReservationRequestCard && (
              <EventSessionSingleReservationRequestCard
                eventSession={eventSession}
                userAccount={userAccount}
                reservationRequestList={reservationRequestList ?? []}
                reloadUserData={handleFetchUserData}
              />
            )}
            {displayConfirmedReservationCard && (
              <EventSessionSingleConfirmedReservationCard
                eventSession={eventSession}
                userAccount={userAccount}
                confirmedReservationList={confirmedReservationList ?? []}
                reloadUserData={handleFetchUserData}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
