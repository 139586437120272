import { useFetcherWithSwr, useSender } from "@/api";
import { isAuthAdminAtom, isAuthStaffAtom } from "@/atoms";
import { Button } from "@/components/ui/button";
import { getJstFromUnixtime } from "@/utils";
import { useAtomValue } from "jotai";
import { PlusIcon, Trash2Icon } from "lucide-react";

type SessionCheckInSegmentProps = {
  reservationId: string;
  userId: string;
};
export const SessionCheckInSegment = ({
  reservationId,
  userId,
}: SessionCheckInSegmentProps) => {
  const isAuthAdmin = useAtomValue(isAuthAdminAtom);
  const isAuthStaff = useAtomValue(isAuthStaffAtom);

  const displayCheckInButton = isAuthAdmin || isAuthStaff;

  const {
    data: userSessionCheckIn,
    mutate: reloadUserSessionCheckIn,
    isLoading: isUserSessionCheckInPending,
  } = useFetcherWithSwr({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "getUserSessionCheckIn",
    requestParameters: { userId, reservationId },
    swrConfiguration: { errorRetryCount: 1 },
  });
  const {
    sender: createUserSessionCheckIn,
    isLoading: isCreateUserSessionCheckInPending,
  } = useSender({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "createUserSessionCheckIn",
  });
  const {
    sender: deleteUserSessionCheckIn,
    isLoading: isDeleteUserSessionCheckInPending,
  } = useSender({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "deleteUserSessionCheckIn",
  });
  const handleCreateUserSessionCheckIn = async () => {
    await createUserSessionCheckIn({ userId, reservationId });
    reloadUserSessionCheckIn();
  };
  const handleDeleteUserSessionCheckIn = async () => {
    await deleteUserSessionCheckIn({ userId, reservationId });
    reloadUserSessionCheckIn();
  };
  return (
    <>
      {isUserSessionCheckInPending ? (
        <>読み込み中...</>
      ) : (
        <>
          {userSessionCheckIn && (
            <p className="text-sm text-green-600">
              {getJstFromUnixtime(userSessionCheckIn.timestamp_created)}
              に受付しました。
            </p>
          )}
          {displayCheckInButton && (
            <>
              {userSessionCheckIn ? (
                <Button
                  size="lg"
                  onClick={handleDeleteUserSessionCheckIn}
                  disabled={isDeleteUserSessionCheckInPending}
                  variant="destructive"
                >
                  <Trash2Icon className="mr-2 h-4 w-4" />
                  チェックインを取り消す
                </Button>
              ) : (
                <Button
                  size="lg"
                  onClick={handleCreateUserSessionCheckIn}
                  disabled={isCreateUserSessionCheckInPending}
                >
                  <PlusIcon className="mr-2 h-4 w-4" />
                  チェックイン
                </Button>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
