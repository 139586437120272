import { useSender } from "@/api";
import { appConfigAtom } from "@/atoms";
import { Alert } from "@/components/ui/alert";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { genderMap, participantCategoryMap } from "@/constants";
import {
  ParticipantCategory,
  SecureApplicantCreate,
} from "@/generatedApi/eventManagerApi";
import { useAtomValue } from "jotai";
import { ChevronLeft, SaveIcon, Trash2Icon } from "lucide-react";
import { useCallback, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

export const UserSingleEditApplicantPage = () => {
  const { userId } = useParams<{ userId: string }>();

  const {
    data: secureUserAccount,
    sender: fetchSecureUserAccount,
    isLoading: isFetchSecureUserAccountPending,
    // error: fetchSecureUserAccountError,
  } = useSender({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "getSecureUserAccount",
  });

  const {
    sender: updateApplicant,
    isLoading: isUpdateApplicantPending,
    // error: updateApplicantError,
  } = useSender({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "updateApplicant",
  });

  const loading = isFetchSecureUserAccountPending || isUpdateApplicantPending;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SecureApplicantCreate>({
    // @ts-expect-error todo
    defaultValues: async () => {
      const res = await fetchSecureUserAccount({ userId: userId ?? "" });
      return res?.applicant;
    },
  });

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<SecureApplicantCreate> = async (data) => {
    if (!userId) return;
    const res = await updateApplicant({ userId, requestBody: data });
    if (res === undefined) {
      toast.error("保存に失敗しました。運営にお問い合わせください。", {});
    } else {
      toast.success("申込者情報を保存しました", {});
      navigate(`/users/${userId}`);
    }
  };

  const hasParticipantList = useMemo(() => {
    if (!secureUserAccount) return false;
    if (!secureUserAccount.participant_list) return false;
    return secureUserAccount.participant_list.items.length > 0;
  }, [secureUserAccount]);

  ///

  const { sender: deleteApplicant, isLoading: isDeleteApplicantPending } =
    useSender({
      apiName: "event_manager",
      serviceName: "users",
      operationId: "deleteApplicant",
    });
  const handleDeleteApplicant = useCallback(async () => {
    if (!userId) return;
    const res = await deleteApplicant({ userId });
    if (res === undefined) {
      toast.error("削除に失敗しました。運営にお問い合わせください。", {});
    } else toast.success("申込者情報を削除しました", {});
    navigate(`/users/${userId}`);
  }, [deleteApplicant, userId, navigate]);

  const appConfig = useAtomValue(appConfigAtom);
  if (!appConfig) return "...";

  return (
    <>
      <Helmet>
        <title>編集</title>
      </Helmet>
      <div className="mb-8">
        <Link
          to={`/users/${userId}`}
          className={buttonVariants({ variant: "secondary", size: "sm" })}
        >
          <ChevronLeft className="mr-1 h-4 w-4" />
          参加者情報ページへ戻る
        </Link>
      </div>
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center justify-between">
            申込者
            {appConfig.flags.is_user_account_updatable && (
              <Dialog>
                <DialogTrigger>
                  <Button
                    variant="outline"
                    size="sm"
                    disabled={
                      isDeleteApplicantPending || isUpdateApplicantPending
                    }
                  >
                    <Trash2Icon className="mr-1 h-4 w-4" />
                    申込者情報をクリア
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>申込者情報をクリア</DialogTitle>
                    <DialogDescription>
                      {hasParticipantList && (
                        <p className="text-sm text-red-400">
                          参加者リストが登録されているため、申込者情報をクリアできません。
                          <br />
                          申込者情報をクリアするには、参加者リストをクリアしてください。
                        </p>
                      )}
                    </DialogDescription>
                  </DialogHeader>
                  <DialogFooter>
                    <Button
                      variant="destructive"
                      onClick={() => handleDeleteApplicant()}
                      disabled={isDeleteApplicantPending || hasParticipantList}
                    >
                      クリアする
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            )}
          </CardTitle>
        </CardHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <div className="grid gap-4">
              <div className="grid gap-2">
                <Label htmlFor="rep-name">氏名</Label>
                <Input
                  disabled={loading}
                  {...register("name", {
                    required: "氏名を入力してください",
                    minLength: { value: 2, message: "入力値が短すぎます" },
                    maxLength: { value: 100, message: "入力値が長すぎます" },
                  })}
                  id="rep-name"
                  type="text"
                  placeholder="田中 太郎"
                />
                <p className="text-sm text-red-400">{errors.name?.message}</p>
              </div>
              <div className="grid gap-2">
                <Label htmlFor="rep-tel">電話番号</Label>
                <Input
                  disabled={loading}
                  {...register("secure_phone", {
                    required: "電話番号を入力してください",
                    minLength: { value: 2, message: "入力値が短すぎます" },
                    maxLength: { value: 100, message: "入力値が長すぎます" },
                  })}
                  id="rep-tel"
                  type="tel"
                  placeholder="000-000-0000"
                />
                <p className="text-sm text-red-400">
                  {errors.secure_phone?.message}
                </p>
              </div>
              <div className="grid gap-2">
                <Label htmlFor="rep-type">種別</Label>
                <Controller
                  name="participant_category"
                  rules={{ required: "種別を選択してください" }}
                  control={control}
                  render={({ field }) => (
                    <Select
                      disabled={loading}
                      onValueChange={field.onChange}
                      value={field.value}
                    >
                      <SelectTrigger className="">
                        <SelectValue placeholder="種別を選択してください" />
                      </SelectTrigger>
                      <SelectContent id="rep-type">
                        <SelectGroup>
                          {[
                            ParticipantCategory.ADULT,
                            ParticipantCategory.HIGHER_EDUCATION,
                            ParticipantCategory.HIGH_SCHOOL,
                            ParticipantCategory.JUNIOR_HIGH_SCHOOL,
                          ].map((category) => (
                            <SelectItem value={category} key={category}>
                              {participantCategoryMap[category]}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  )}
                />
                <p className="text-sm text-red-400">
                  {errors.participant_category?.message}
                </p>
              </div>
              <div className="grid gap-2">
                <Label htmlFor="rep-school">学校名(生徒・学生のみ入力)</Label>
                <Input
                  disabled={loading}
                  {...register("school", {
                    validate: (value, formValues) => {
                      if (
                        formValues.participant_category ===
                        ParticipantCategory.ADULT
                      )
                        return true;
                      if (!value || value.length < 3)
                        return "学校名を入力してください";
                    },
                  })}
                  id="rep-school"
                  type="text"
                  placeholder="〇〇高校"
                />
                <p className="text-sm text-red-400">{errors.school?.message}</p>
              </div>
              <div className="grid gap-2">
                <Label htmlFor="rep-gender">性別</Label>
                <Controller
                  name="gender"
                  rules={{ required: "性別を選択してください" }}
                  control={control}
                  render={({ field }) => (
                    <Select
                      disabled={loading}
                      onValueChange={field.onChange}
                      value={field.value}
                    >
                      <SelectTrigger className="">
                        <SelectValue placeholder="性別を選択してください" />
                      </SelectTrigger>
                      <SelectContent id="rep-gender">
                        <SelectGroup>
                          {Object.entries(genderMap).map(
                            ([category, label]) => (
                              <SelectItem value={category} key={category}>
                                {label}
                              </SelectItem>
                            )
                          )}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  )}
                />
                <p className="text-sm text-red-400">{errors.gender?.message}</p>
              </div>
              <div className="grid gap-2">
                <Label htmlFor="rep-city">県・市町村</Label>
                <Input
                  disabled={loading}
                  {...register("city", {
                    required: "県・市町村を入力してください",
                    minLength: { value: 2, message: "入力値が短すぎます" },
                    maxLength: { value: 100, message: "入力値が長すぎます" },
                  })}
                  id="rep-city"
                  type="text"
                  placeholder="愛知県刈谷市"
                />
                <p className="text-sm text-red-400">{errors.city?.message}</p>
              </div>
            </div>
          </CardContent>
          <CardFooter className="grid gap-4">
            {appConfig.flags.is_user_account_updatable ? (
              <>
                {hasParticipantList && (
                  <p className="text-red-400">
                    参加者情報が登録されているため、申込者情報の変更はできません。
                  </p>
                )}
                <Button
                  variant="default"
                  type="submit"
                  disabled={loading || hasParticipantList}
                >
                  <SaveIcon className="mr-2 h-4 w-4" />
                  {isUpdateApplicantPending ? "保存中..." : "保存"}
                </Button>
              </>
            ) : (
              <Alert variant="destructive">変更受付期間外です。</Alert>
            )}
          </CardFooter>
        </form>
      </Card>
    </>
  );
};
