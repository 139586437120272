import { useFetcherWithSwr } from "@/api";
import { EventSession } from "@/generatedApi/eventManagerApi";
import { EventSessionSummaryCard, EventSessionSummaryCardProps } from ".";

export type AutonomousEventSessionSummaryCardProps = EventSessionSummaryCardProps & {
  eventSessionId: string;
  eventSession?: EventSession;
};
export const AutonomousEventSessionSummaryCard = ({
  eventSessionId,
  ...otherProps
}: AutonomousEventSessionSummaryCardProps) => {
  const { data: eventSession } = useFetcherWithSwr({
    apiName: "event_manager",
    serviceName: "eventSessions",
    operationId: "getEventSession",
    requestParameters: { eventSessionId: eventSessionId },
  });
  return (
    <EventSessionSummaryCard {...otherProps} eventSession={eventSession} />
  );
};
