import { appConfigAtom } from "@/atoms";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useAtomValue } from "jotai";
import { ChevronRight } from "lucide-react";
import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { AUTH_STEP, SignUpInput, useSignUp } from "./auth";

export type SignUpFormProps = {
  setEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
  setPassword: React.Dispatch<React.SetStateAction<string | undefined>>;
  setStep: React.Dispatch<React.SetStateAction<AUTH_STEP>>;
};

export const SignUpForm = ({
  setEmail,
  setPassword,
  setStep,
}: SignUpFormProps) => {
  const {
    register: authRegister,
    handleSubmit: handleAuthSubmit,
    formState: { errors, isLoading },
    setError,
  } = useForm<SignUpInput>();

  const setRootError = useCallback(
    (message: string) => {
      setError("root", { message: message });
    },
    [setError]
  );

  const { sender } = useSignUp({ setRootError, setStep });

  const handleSignUp = useCallback(
    async (data: SignUpInput) => {
      setEmail(data.email);
      setPassword(data.password);
      await sender(data);
    },
    [setEmail, setPassword, sender]
  );

  const appConfig = useAtomValue(appConfigAtom);
  if (!appConfig) {
    return null;
  }

  return (
    <form
      className="grid gap-4"
      text-left
      onSubmit={handleAuthSubmit(handleSignUp)}
    >
      <p className="text-sm">
        まずはメールアドレスの登録を行います。メールアドレスとご希望のパスワードを入力して登録ボタンを押してください。登録メールアドレス宛に認証コードが送信されます。(
        {appConfig.displays.sender_email}
        からのメールが受信できるように設定してください。)
      </p>
      <div className="grid gap-2">
        <Label htmlFor="email">メールアドレス</Label>
        <Input
          {...authRegister("email", {
            required: "メールアドレスを入力してください",
          })}
          id="email"
          type="email"
          placeholder="mail@example.com"
          disabled={isLoading}
        />
        <p className="text-red-400">{errors.email?.message}</p>
      </div>
      <div className="grid gap-2">
        <Label htmlFor="password">パスワード(英数字を含む8文字以上)</Label>
        <Input
          {...authRegister("password", {
            required: "パスワードを入力してください",
          })}
          id="password"
          type="password"
          disabled={isLoading}
        />
        <p className="text-red-400">{errors.password?.message}</p>
      </div>
      <Button type="submit" disabled={isLoading}>
        登録 <ChevronRight className="ml-1 h-4 w-4" />
      </Button>
      <p className="text-red-400">{errors.root?.message}</p>
    </form>
  );
};
