import { buttonVariants } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ChevronLeft } from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ConfirmResetPasswordForm } from "./ConfirmResetPasswordForm";
import { ConfirmSignInForm } from "./ConfirmSignInForm";
import { ConfirmSignUpForm } from "./ConfirmSignUpForm";
import { ResetPasswordForm } from "./ResetPasswordForm";
import { SignInForm } from "./SignInForm";
import { SignUpForm } from "./SignUpForm";
import { AUTH_STEP } from "./auth";

type AuthPage = {
  initialStep?: AUTH_STEP;
};
export const AuthPage = ({ initialStep }: AuthPage) => {
  const [step, setStep] = useState<AUTH_STEP>(initialStep ?? "SIGN_UP");

  const [email, setEmail] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const [confirmationCode, setConfirmationCode] = useState<
    string | undefined
  >();

  return (
    <div className="mx-auto grid max-w-md gap-4 p-4">
      <Card className="">
        <CardHeader>
          <CardTitle className="text-2xl">
            <img src="/logo.png" alt="logo" className="mb-2 block w-52" />
            名大テックフェス2024
            <br />
            {step === "SIGN_UP" && "新規参加登録"}
            {step === "CONFIRM_SIGN_UP" && "メール認証"}
            {step === "SIGN_IN" && "ログイン"}
            {step === "RESET_PASSWORD" && "パスワードリセット"}
            {step === "CONFIRM_RESET_PASSWORD" && "パスワード再設定"}
            {step === "CONFIRM_SIGN_IN" && "新規パスワード設定"}
          </CardTitle>
        </CardHeader>
        <CardContent>
          {step === "SIGN_UP" && (
            <SignUpForm
              setEmail={setEmail}
              setPassword={setPassword}
              setStep={setStep}
            />
          )}
          {step === "CONFIRM_SIGN_UP" && (
            <ConfirmSignUpForm
              setConfirmationCode={setConfirmationCode}
              setStep={setStep}
              email={email}
              password={password}
            />
          )}
          {step === "SIGN_IN" && (
            <SignInForm
              setStep={setStep}
              setEmail={setEmail}
              setPassword={setPassword}
            />
          )}
          {step === "RESET_PASSWORD" && (
            <ResetPasswordForm setStep={setStep} setEmail={setEmail} />
          )}
          {step === "CONFIRM_RESET_PASSWORD" && (
            <ConfirmResetPasswordForm
              setStep={setStep}
              email={email}
              setPassword={setPassword}
            />
          )}
          {step === "CONFIRM_SIGN_IN" && (
            <ConfirmSignInForm setStep={setStep} setPassword={setPassword} />
          )}
        </CardContent>
      </Card>
      <Link to="/" className={buttonVariants({ variant: "outline" })}>
        <ChevronLeft className="mr-1 h-4 w-4" />
        キャンセル
      </Link>
    </div>
  );
};
