/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EventSessionEligible {
    NOT_ALLOWED = 'not_allowed',
    ALLOWED = 'allowed',
    ALLOWED_WITH_ELEMENTARY_SCHOOL = 'allowed_with_elementary_school',
    ALLOWED_WITH_ELEMENTARY_OR_JUNIOR_HIGH_SCHOOL = 'allowed_with_elementary_or_junior_high_school',
}
