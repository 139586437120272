import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ChevronRight } from "lucide-react";
import React from "react";
import { useForm } from "react-hook-form";
import {
    AUTH_STEP,
    ConfirmResetPasswordInput,
    useConfirmResetPassword
} from "./auth";

type ConfirmResetPasswordFormProps = {
  setStep: React.Dispatch<React.SetStateAction<AUTH_STEP>>;
  email: string | undefined;
  setPassword: React.Dispatch<React.SetStateAction<string | undefined>>;
};
export const ConfirmResetPasswordForm = ({
  setStep,
  email,
  setPassword,
}: ConfirmResetPasswordFormProps) => {
  const {
    register: authRegister,
    handleSubmit: handleAuthSubmit,
    formState: { errors, isLoading },
    setError,
  } = useForm<ConfirmResetPasswordInput>();
  const setRootError = (message: string) => {
    setError("root", { message: message });
  };
  const { sender } = useConfirmResetPassword({
    setStep,
    setRootError,
  });
  const hanldeConfirmResetPassword = (data: ConfirmResetPasswordInput) => {
    setPassword(data.newPassword);
    sender(data);
  };
  return (
    <form
      className="grid gap-4"
      text-left
      onSubmit={handleAuthSubmit(hanldeConfirmResetPassword)}
    >
      <div className="grid gap-2">
        <Label htmlFor="email">メールアドレス</Label>
        <Input
          {...authRegister("email", {
            required: "メールアドレスを入力してください",
            value: email,
          })}
          id="email"
          type="email"
          placeholder="example@hashup.co.jp"
          disabled
        />
        <p className="text-red-400">{errors.email?.message}</p>
      </div>
      <div className="grid gap-2">
        <Label htmlFor="confirmationCode">確認コード</Label>
        <Input
          {...authRegister("confirmationCode", {
            required: "確認コードを入力してください",
          })}
          id="confirmationCode"
          type="text"
          placeholder="123456"
        />
        <p className="text-red-400">{errors.confirmationCode?.message}</p>
      </div>
      <div className="grid gap-2">
        <Label htmlFor="password">新しいパスワード</Label>
        <Input
          {...authRegister("newPassword", {
            required: "パスワードを入力してください",
          })}
          id="password"
          type="password"
        />
        <p className="text-red-400">{errors.newPassword?.message}</p>
      </div>
      <Button type="submit" disabled={isLoading}>
        パスワードリセット <ChevronRight className="ml-1 h-4 w-4" />
      </Button>
      <p className="text-red-400">{errors.root?.message}</p>
    </form>
  );
};
