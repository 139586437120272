import { useFetcherWithSwr } from "@/api";
import { EventSession, UserAccount } from "@/generatedApi/eventManagerApi";
import { CapacityDisplayBase } from ".";

type ReservationRequestCapacityDisplayProps = {
  eventSession: EventSession;
  userAccount?: UserAccount;
  showCapacityFilled?: boolean;
};
export const ReservationRequestCapacityDisplay = ({
  eventSession,
  userAccount,
  showCapacityFilled = true,
}: ReservationRequestCapacityDisplayProps) => {
  const { data: participantCount } = useFetcherWithSwr({
    apiName: "event_manager",
    serviceName: "eventSessions",
    operationId: "getEventSessionReservationRequestParticipantCount",
    requestParameters: { eventSessionId: eventSession.event_session_id },
  });
  const { data: reservationRequestCapacityFilledRate } = useFetcherWithSwr({
    apiName: "event_manager",
    serviceName: "eventSessions",
    operationId: "getEventSessionReservationRequestCapacityFilledRate",
    requestParameters: {
      eventSessionId: eventSession.event_session_id,
      userId: userAccount?.user_id,
    },
  });

  return (
    <CapacityDisplayBase
      capacityFilledRate={reservationRequestCapacityFilledRate}
      participantCount={participantCount}
      maxCapacity={eventSession.max_capacity}
      capacitySuffix="名が希望中"
      showCapacityFilled={showCapacityFilled}
    />
  );
};
