import { Button, buttonVariants } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ChevronRight } from "lucide-react";
import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { AUTH_STEP, SignInInput, useSignIn } from "./auth";

type SignInFormProps = {
  setStep: React.Dispatch<React.SetStateAction<AUTH_STEP>>;
  setEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
  setPassword: React.Dispatch<React.SetStateAction<string | undefined>>;
};
export const SignInForm = ({
  setStep,
  setEmail,
  setPassword,
}: SignInFormProps) => {
  const {
    register: authRegister,
    handleSubmit: handleAuthSubmit,
    formState: { errors, isLoading },
    setError,
  } = useForm<SignInInput>();

  const setRootError = (message: string) => {
    setError("root", { message: message });
  };

  const { sender } = useSignIn({ setRootError, setStep });

  const handleSignIn = async (data: SignInInput) => {
    setEmail(data.email);
    setPassword(data.password);
    await sender(data);
  };

  return (
    <form
      className="grid gap-4"
      text-left
      onSubmit={handleAuthSubmit(handleSignIn)}
    >
      <p className="text-sm">
        新規参加登録時に設定したメールアドレスとパスワードを入力してログインボタンを押してください。ログインすると参加者情報登録、参加希望セッション(講義、体験教室、見学)登録を行うことができます。また、参加希望登録状況も確認できます。
      </p>
      <div className="grid gap-2">
        <Label htmlFor="email">メールアドレス</Label>
        <Input
          {...authRegister("email", {
            required: "メールアドレスを入力してください",
          })}
          id="email"
          type="email"
          placeholder="example@hashup.jp"
          disabled={isLoading}
        />
        <p className="text-red-400">{errors.email?.message}</p>
      </div>
      <div className="grid gap-2">
        <Label htmlFor="password">パスワード</Label>
        <Input
          {...authRegister("password", {
            required: "パスワードを入力してください",
          })}
          id="password"
          type="password"
          disabled={isLoading}
        />
        <p className="text-red-400">{errors.password?.message}</p>
      </div>
      <Button type="submit" disabled={isLoading}>
        ログイン <ChevronRight className="ml-1 h-4 w-4" />
      </Button>
      <p className="text-red-400">{errors.root?.message}</p>
      <Link
        to="/reset"
        className={buttonVariants({ variant: "ghost" })}
        onClick={() => setStep("RESET_PASSWORD")}
      >
        パスワードを忘れた方
        <ChevronRight className="ml-1 h-4 w-4" />
      </Link>
    </form>
  );
};
