import { useFetcherWithSwr, useSender } from "@/api";
import { appConfigAtom } from "@/atoms";
import { Alert } from "@/components/ui/alert";
import { Badge } from "@/components/ui/badge";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { participantCategoryMap } from "@/constants";
import {
  ParticipantListCreate,
  ParticipantType,
} from "@/generatedApi/eventManagerApi";
import { DialogDescription } from "@radix-ui/react-dialog";
import { useAtomValue } from "jotai";
import { ChevronLeft, PlusIcon, SaveIcon, Trash2Icon } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

export const UserSingleEditParticipantListPage = () => {
  const { userId } = useParams<{ userId: string }>();

  const {
    data: userAccount,
    sender: fetchUserAccount,
    isLoading: isFetchUserAccountPending,
    // error: fetchUserAccountError,
  } = useSender({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "getUserAccount",
  });

  const {
    sender: updateParticipantList,
    isLoading: isUpdateParticipantListPending,
    // error: updateParticipantListError,
  } = useSender({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "updateParticipantList",
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ParticipantListCreate>({
    // @ts-expect-error todo
    defaultValues: async () => {
      const res = await fetchUserAccount({ userId: userId ?? "" });
      const memberItems = res?.participant_list?.items.filter(
        (item) => item.participant_type === ParticipantType.MEMBER
      );
      const applicantItems = res?.participant_list?.items.filter(
        (item) => item.participant_type === ParticipantType.APPLICANT
      );
      if (applicantItems && applicantItems?.length > 0) {
        setIsApplicantParticipate(true);
      } else {
        setIsApplicantParticipate(false);
      }

      return { items: memberItems };
    },
  });

  const loading = isFetchUserAccountPending || isUpdateParticipantListPending;

  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  useEffect(() => {
    if (!userId) return;
    fetchUserAccount({ userId });
  }, [userId, fetchUserAccount]);

  const [isApplicantParticipate, setIsApplicantParticipate] = useState(true);

  const MAX_PARTICIPANT_COUNT = 5;
  const exceededParticipantCount = useMemo(() => {
    if (isApplicantParticipate) {
      return fields.length + 1 >= MAX_PARTICIPANT_COUNT;
    } else {
      return fields.length >= MAX_PARTICIPANT_COUNT;
    }
  }, [fields, isApplicantParticipate]);

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<ParticipantListCreate> = async (data) => {
    if (!userId) return;
    if (isApplicantParticipate && userAccount?.applicant) {
      if (data.items === undefined) {
        data.items = [];
      }
      data.items.unshift({
        name: userAccount.applicant.name,
        participant_category: userAccount.applicant.participant_category,
        participant_type: ParticipantType.APPLICANT,
      });
    }
    const res = await updateParticipantList({ userId, requestBody: data });
    if (res === undefined) {
      toast.error("参加者情報の保存に失敗しました", {});
    } else {
      toast.success("参加者情報を保存しました", {
        duration: 3000,
      });
      navigate(`/users/${userId}`);
    }
  };

  ///

  const { data: reservationRequestList, mutate: reloadReservationRequestList } =
    useFetcherWithSwr({
      apiName: "event_manager",
      serviceName: "users",
      operationId: "getReservationRequestListByUserId",
      requestParameters: { userId: userId ?? "" },
    });

  const hasReservationRequest = useMemo(() => {
    if (!reservationRequestList) return false;
    return reservationRequestList?.length !== 0;
  }, [reservationRequestList]);

  ///

  const {
    data: confirmedReservationList,
    mutate: reloadConfirmedReservationList,
  } = useFetcherWithSwr({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "getConfirmedReservationListByUserId",
    requestParameters: { userId: userId ?? "" },
  });

  const hasConfirmedReservation = useMemo(() => {
    if (!confirmedReservationList) return false;
    return confirmedReservationList?.length !== 0;
  }, [confirmedReservationList]);

  ///

  const {
    sender: deleteParticipantList,
    isLoading: isDeleteParticipantListPending,
  } = useSender({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "deleteParticipantList",
  });

  const handleDeleteParticipantList = useCallback(async () => {
    if (!userId) return;
    const res = await deleteParticipantList({ userId });
    if (res === undefined) {
      toast.error("参加者情報の削除に失敗しました", {});
    } else {
      toast.success("参加者情報を削除しました", {
        duration: 3000,
      });
      navigate(`/users/${userId}`);
    }
  }, [deleteParticipantList, userId, navigate]);

  const appConfig = useAtomValue(appConfigAtom);
  useEffect(() => {
    reloadReservationRequestList();
    reloadConfirmedReservationList();
  }, [appConfig, reloadReservationRequestList, reloadConfirmedReservationList]);

  if (!appConfig) return <>読み込み中...</>;

  return (
    <>
      <Helmet>
        <title>編集</title>
      </Helmet>
      <div className="mb-8">
        <Link
          to={`/users/${userId}`}
          className={buttonVariants({ variant: "secondary", size: "sm" })}
        >
          <ChevronLeft className="mr-1 h-4 w-4" />
          参加者情報ページへ戻る
        </Link>
      </div>
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center justify-between">
            参加者
            {appConfig.flags.is_user_account_updatable && (
              <Dialog>
                <DialogTrigger>
                  <Button
                    variant="outline"
                    size="sm"
                    disabled={
                      isDeleteParticipantListPending ||
                      isUpdateParticipantListPending
                    }
                  >
                    <Trash2Icon className="mr-1 h-4 w-4" />
                    参加者リストをクリア
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>参加者リストをクリアする</DialogTitle>
                    <DialogDescription>
                      {hasReservationRequest && (
                        <p className="text-sm text-red-400">
                          セッションを登録しているため、参加者リストをクリアできません。
                          <br />
                          登録中のセッションを削除してから再度お試しください。
                        </p>
                      )}
                    </DialogDescription>
                  </DialogHeader>
                  <DialogFooter>
                    <Button
                      variant="destructive"
                      onClick={() => handleDeleteParticipantList()}
                      disabled={
                        isDeleteParticipantListPending || hasReservationRequest
                      }
                    >
                      クリアする
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            )}
          </CardTitle>
        </CardHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <div className="-mx-3 grid gap-2">
              <div className="grid gap-3 rounded-md border p-3">
                <div className="flex items-center space-x-2">
                  <Switch
                    id="applicant-participate"
                    checked={isApplicantParticipate}
                    disabled={
                      (!isApplicantParticipate && exceededParticipantCount) ||
                      loading
                    }
                    onCheckedChange={(checked) => {
                      if (checked && exceededParticipantCount) {
                        toast.success("参加者数が上限に達しています", {});
                        return;
                      }
                      setIsApplicantParticipate(checked);
                    }}
                  />
                  <Label htmlFor="applicant-participate">
                    申込者も参加する
                  </Label>
                </div>
                {!isApplicantParticipate && exceededParticipantCount && (
                  <p className="text-sm text-red-400">
                    参加者数が上限に達しています。申込者も参加するにはメンバー数を減らしてください。
                  </p>
                )}
              </div>
              {isApplicantParticipate && (
                <div className="grid gap-3 rounded-md border p-3">
                  <div className="flex items-center justify-between">
                    <Badge variant="secondary">申込者</Badge>
                  </div>
                  <div className="grid gap-2">
                    <div>
                      <div className="text-sm text-muted-foreground">氏名</div>
                      <div className="">{userAccount?.applicant?.name}</div>
                    </div>
                    <div>
                      <div className="text-sm text-muted-foreground">種別</div>
                      <div className="">
                        {userAccount?.applicant?.participant_category &&
                          participantCategoryMap[
                            userAccount?.applicant?.participant_category
                          ]}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {fields.map((item, index) => (
                <div key={item.id} className="grid gap-3 rounded-md border p-3">
                  <div className="flex items-center justify-between">
                    <Badge variant="secondary">メンバー{index + 1}</Badge>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => remove(index)}
                    >
                      <Trash2Icon className="mr-1 h-4 w-4" />
                      削除
                    </Button>
                  </div>
                  <div className="grid gap-2">
                    <Label htmlFor={`items.${index}.name`}>氏名</Label>
                    <Input
                      {...register(`items.${index}.name`, {
                        required: "氏名を入力してください",
                        minLength: { value: 2, message: "入力値が短すぎます" },
                        maxLength: {
                          value: 100,
                          message: "入力値が長すぎます",
                        },
                      })}
                      disabled={loading}
                      id={`items.${index}.name`}
                      type="text"
                      placeholder="田中 太郎"
                    />
                    <p className="text-sm text-red-400">
                      {errors.items?.[index]?.name?.message}
                    </p>
                  </div>
                  <div className="grid gap-2">
                    <Label htmlFor={`items.${index}.type`}>種別</Label>
                    <Controller
                      name={`items.${index}.participant_category`}
                      rules={{ required: "種別を選択してください" }}
                      control={control}
                      render={({ field }) => (
                        <Select
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <SelectTrigger className="">
                            <SelectValue placeholder="種別を選択してください" />
                          </SelectTrigger>
                          <SelectContent id="rep-type">
                            <SelectGroup>
                              {Object.entries(participantCategoryMap).map(
                                ([category, label]) => (
                                  <SelectItem value={category} key={category}>
                                    {label}
                                  </SelectItem>
                                )
                              )}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      )}
                    />
                    <p className="text-sm text-red-400">
                      {errors.items?.[index]?.participant_category?.message}
                    </p>
                    <Input
                      type="hidden"
                      {...register(`items.${index}.participant_type`)}
                    />
                  </div>
                  <div className="grid gap-2">
                    <Label htmlFor={`items.${index}.school`}>
                      学校名（生徒・学生の場合のみ）
                    </Label>
                    <Input
                      {...register(`items.${index}.school`, {})}
                      disabled={loading}
                      id={`items.${index}.school`}
                      type="text"
                      placeholder="刈谷高校"
                    />
                    <p className="text-sm text-red-400">
                      {errors.items?.[index]?.name?.message}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            {!exceededParticipantCount && (
              <div className="mb-8 text-center">
                <Button
                  variant="outline"
                  size="lg"
                  className="mt-4"
                  onClick={() =>
                    append({
                      name: "",
                      participant_type: ParticipantType.MEMBER,
                      // @ts-expect-error participant_category is required
                      participant_category: undefined,
                    })
                  }
                  disabled={loading}
                >
                  <PlusIcon className="mr-1 h-4 w-4" />
                  参加者を追加
                </Button>
              </div>
            )}
          </CardContent>
          <CardFooter className="grid gap-4">
            {appConfig.flags.is_user_account_updatable ? (
              <>
                {(hasReservationRequest &&
                  appConfig.flags.is_reservation_request_updatable) ||
                hasConfirmedReservation ? (
                  <p className="text-sm text-red-400">
                    セッションを登録しているため、参加者情報の変更はできません。
                    <br />
                    登録中のセッションを削除してから再度お試しください。
                  </p>
                ) : (
                  <Button variant="default" type="submit" disabled={loading}>
                    <SaveIcon className="mr-2 h-4 w-4" />
                    保存
                  </Button>
                )}
              </>
            ) : (
              <Alert variant="destructive">変更受付期間外です。</Alert>
            )}
          </CardFooter>
        </form>
      </Card>
    </>
  );
};
