import { fetchAuthSession } from "aws-amplify/auth";
import { useCallback, useState } from "react";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  EventManagerApiClient,
  OpenAPIConfig,
} from "./generatedApi/eventManagerApi";

type Constructor<T = any> = new (...args: any[]) => T;

export const apiNameConstructorMap = {
  event_manager: EventManagerApiClient,
};

export type ApiName = keyof typeof apiNameConstructorMap;

export type NonNullableData<T> = T extends void | undefined | null
  ? "__fetchSuccess__"
  : T;

export type ClientClassConstructor<S extends ApiName> =
  (typeof apiNameConstructorMap)[S] extends Constructor
    ? (typeof apiNameConstructorMap)[S]
    : never;

export type ClientServiceName<
  S extends ApiName,
  T extends ClientClassConstructor<S>
> = keyof InstanceType<T>;

export type ClientService<
  S extends ApiName,
  T extends ClientClassConstructor<S>,
  U extends ClientServiceName<S, T>
> = InstanceType<T>[U];

export type ClientOperationId<
  S extends ApiName,
  T extends ClientClassConstructor<S>,
  U extends ClientServiceName<S, T>,
  V extends ClientService<S, T, U>
> = keyof V;

export type Fetcher = <
  S extends ApiName,
  T extends ClientClassConstructor<S>,
  U extends ClientServiceName<S, T>,
  V extends ClientService<S, T, U>,
  W extends ClientOperationId<S, T, U, V>
>(
  // ClientConfiguration: ClientConfigurationConstructor,
  apiName: S,
  serviceName: U,
  operationId: W,
  requestParameters: Parameters<V[W]>[0]
  // initOverrides?: Parameters<InstanceType<T>[U]>[1]
) => Promise<{
  data: NonNullableData<Awaited<ReturnType<V[W]>>> | undefined;
  error: any;
}>;

export const useFetcher = () => {
  const fetcher: Fetcher = async (
    apiName,
    serviceName,
    operationId,
    requestParameters
  ) => {
    const token = (await fetchAuthSession()).tokens?.accessToken.toString();
    const params: Partial<OpenAPIConfig> = {
      BASE: import.meta.env.VITE_API_ENDPOINT,
      TOKEN: token,
    };

    const cli = new apiNameConstructorMap[apiName](params);
    try {
      // @ts-expect-error
      const res = await cli[serviceName][operationId](requestParameters);
      return { data: res, error: null };
    } catch (e) {
      return { data: undefined, error: e };
    }
  };
  return fetcher;
};

export type Sender = <
  S extends ApiName,
  T extends ClientClassConstructor<S>,
  U extends ClientServiceName<S, T>,
  V extends ClientService<S, T, U>,
  W extends ClientOperationId<S, T, U, V>
>(
  // ClientConfiguration: ClientConfigurationConstructor,
  apiName: S,
  serviceName: U,
  operationId: W
  // initOverrides?: Parameters<InstanceType<T>[U]>[1]
) => Promise<{
  data: NonNullableData<Awaited<ReturnType<V[W]>>> | undefined;
  error: any;
  sender: (
    args: Parameters<V[W]>[0]
  ) => Promise<NonNullableData<Awaited<ReturnType<V[W]>>>>;
}>;

export type useSenderProps<
  S extends ApiName,
  T extends ClientClassConstructor<S>,
  U extends ClientServiceName<S, T>,
  V extends ClientService<S, T, U>,
  W extends ClientOperationId<S, T, U, V>
> = {
  apiName: S;
  serviceName: U;
  operationId: W;
};

export const useSender = <
  S extends ApiName,
  T extends ClientClassConstructor<S>,
  U extends ClientServiceName<S, T>,
  V extends ClientService<S, T, U>,
  W extends ClientOperationId<S, T, U, V>
>({
  apiName,
  serviceName,
  operationId,
}: useSenderProps<S, T, U, V, W>): {
  data: NonNullableData<Awaited<ReturnType<V[W]>>> | undefined;
  error: any;
  isLoading: boolean;
  sender: (
    args: Parameters<V[W]>[0]
  ) => Promise<NonNullableData<ReturnType<V[W]>> | undefined>;
} => {
  const [data, setData] = useState<
    NonNullableData<Awaited<ReturnType<V[W]>>> | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  const fetcher = useFetcher();

  const sender = useCallback(
    async (
      requestData: Parameters<V[W]>[0]
    ): Promise<NonNullableData<ReturnType<V[W]>> | undefined> => {
      setIsLoading(true);
      setError(null);
      setData(undefined);
      const { data: res, error: err } = await fetcher(
        apiName,
        serviceName,
        operationId,
        requestData
      );
      if (err) {
        setError(err);
      } else {
        setData(res);
      }
      setIsLoading(false);
      return res;
    },
    []
  );

  return { data, isLoading, error, sender };
};

export type useFetcherWithSrwProps<
  S extends ApiName,
  T extends ClientClassConstructor<S>,
  U extends ClientServiceName<S, T>,
  V extends ClientService<S, T, U>,
  W extends ClientOperationId<S, T, U, V>
> = {
  apiName: S;
  serviceName: U;
  operationId: W;
  requestParameters: Parameters<V[W]>[0];
  swrConfiguration?: SWRConfiguration;
};

export const useFetcherWithSwr = <
  S extends ApiName,
  T extends ClientClassConstructor<S>,
  U extends ClientServiceName<S, T>,
  V extends ClientService<S, T, U>,
  W extends ClientOperationId<S, T, U, V>
>({
  apiName,
  serviceName,
  operationId,
  requestParameters,
  swrConfiguration,
}: useFetcherWithSrwProps<S, T, U, V, W>): SWRResponse<
  NonNullableData<Awaited<ReturnType<V[W]>>>,
  any,
  any
> & { cacheKey: string } => {
  const cacheKey = `${apiName}.${serviceName as string}.${
    operationId as string
  }.${JSON.stringify(requestParameters)}`;
  const fetcher = useFetcher();
  const apiCall = async () => {
    const { data: res, error: err } = await fetcher(
      apiName,
      serviceName,
      operationId,
      requestParameters
    );
    if (err) {
      if (err.status === 404) {
        return null;
      }
      throw err;
    }
    return res;
  };
  const swrRes = useSWR(cacheKey, apiCall, swrConfiguration);
  // @ts-expect-error TS2322
  return { ...swrRes, cacheKey };
};
