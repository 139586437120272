import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { participantCategoryMap } from "@/constants";
import {
  EventSession,
  ParticipantCategory,
  UserAccount,
} from "@/generatedApi/eventManagerApi";
import { SignalIcon, SignalLowIcon, SignalMediumIcon } from "lucide-react";

type EventSessionSingleRecommendationCardProps = {
  eventSession: EventSession;
  userAccount?: UserAccount;
};
export const EventSessionSingleRecommendationCard = ({
  eventSession,
  userAccount,
}: EventSessionSingleRecommendationCardProps) => {
  const participantCategories =
    userAccount?.participant_list?.items.map(
      (participant) => participant.participant_category
    ) ?? [];
  return (
    <Card>
      <CardHeader>
        <CardTitle>おすすめ度</CardTitle>
      </CardHeader>
      <CardContent className="grid gap-3">
        {Object.entries(participantCategoryMap).map(
          ([participantCategory, label]) => (
            <div
              key={participantCategory}
              className={`flex items-center justify-between ${
                participantCategories.length > 0 &&
                (participantCategories.includes(
                  participantCategory as ParticipantCategory
                )
                  ? ""
                  : "opacity-30")
              }`}
            >
              <div className="">{label}</div>
              {eventSession.recommendations[participantCategory] === 0 && (
                <Badge
                  className="border-yellow-600 text-yellow-600"
                  variant="outline"
                >
                  おすすめしない
                  <SignalLowIcon className="ml-2 h-3 w-3" />
                </Badge>
              )}
              {eventSession.recommendations[participantCategory] === 1 && (
                <Badge
                  className="border-lime-600 text-green-600"
                  variant="outline"
                >
                  ややおすすめ
                  <SignalMediumIcon className="ml-2 h-3 w-3" />
                </Badge>
              )}
              {eventSession.recommendations[participantCategory] === 2 && (
                <Badge
                  className="border-green-600 text-green-600"
                  variant="outline"
                >
                  おすすめ
                  <SignalIcon className="ml-2 h-3 w-3" />
                </Badge>
              )}
            </div>
          )
        )}
      </CardContent>
    </Card>
  );
};
