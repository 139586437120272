import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import { useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { Toaster } from "sonner";
import { useFetcherWithSwr } from "./api";
import {
  appConfigAtom,
  authUserGroupAtom,
  currentAuthUserAtom,
  isLoggedInAtom,
} from "./atoms";
import { AuthCommonShell } from "./common/AuthCommonShell";
import "./index.css";
import { EventSessionListPage } from "./pages/EventSessionListPage";
import { EventSessionSinglePage } from "./pages/EventSessionSinglePage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { PrivacyPolicyPage } from "./pages/PrivacyPolicyPage";
import { TopPage } from "./pages/TopPage";
import { AuthPage } from "./pages/Unauth/AuthPage";
import { UnauthTopPage } from "./pages/Unauth/UnauthTopPage";
import { UserListPage } from "./pages/UserListPage";
import { UserSingleEditApplicantPage } from "./pages/UserSingleEditApplicantPage";
import { UserSingleEditEmergencyContactPage } from "./pages/UserSingleEditEmergencyContactPage";
import { UserSingleEditParticipantListPage } from "./pages/UserSingleEditParticipantListPage";
import { UserSinglePage } from "./pages/UserSinglePage";
import { UserSingleReservationPage } from "./pages/UserSingleReservationPage";

import * as Sentry from "@sentry/react";
import { ReceptionAppPage } from "./pages/ReceptionAppPage";

Sentry.init({
  dsn: "https://9dfea93e619d937e37ec2558fa3df463@o4507464084881408.ingest.us.sentry.io/4507464088879104",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: import.meta.env.MODE,
});

const unauthRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route
        path="*"
        element={
          <div className="p-4">
            <NotFoundPage />
          </div>
        }
      />
      <Route index element={<UnauthTopPage />} />
      <Route path="signin" element={<AuthPage initialStep="SIGN_IN" />} />
      <Route path="signup" element={<AuthPage initialStep="SIGN_UP" />} />
      <Route path="reset" element={<AuthPage initialStep="RESET_PASSWORD" />} />
      <Route
        path="privacy"
        element={
          <div className="p-4">
            <PrivacyPolicyPage />
          </div>
        }
      />
    </Route>
  )
);

const authRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<AuthCommonShell />}>
      <Route path="*" element={<NotFoundPage />} />
      <Route index element={<TopPage />} />
      <Route path="privacy" element={<PrivacyPolicyPage />} />
      <Route path="users">
        <Route index element={<UserListPage />} />
        <Route path=":userId">
          <Route index element={<UserSinglePage />} />
          <Route path="reservations" element={<UserSingleReservationPage />} />
          <Route path="edit">
            <Route path="applicant" element={<UserSingleEditApplicantPage />} />
            <Route
              path="participant_list"
              element={<UserSingleEditParticipantListPage />}
            />
            <Route
              path="emergency_contact"
              element={<UserSingleEditEmergencyContactPage />}
            />
          </Route>
        </Route>
      </Route>
      <Route path="event_sessions">
        <Route index element={<EventSessionListPage />} />
        <Route path=":eventSessionId" element={<EventSessionSinglePage />} />
      </Route>
      <Route path="/reception_app" element={<ReceptionAppPage />} />
    </Route>
  )
);

const Loading = () => {
  return (
    <div className="mx-auto max-w-md p-4">
      <Helmet>
        <title>読み込み中...</title>
      </Helmet>
      <div className="grid justify-center gap-2 text-center">
        <img src="/logo.png" alt="logo" className="mb-2 block w-52" />
        <div className="m-auto h-2 w-2 animate-ping rounded-full bg-slate-600"></div>
      </div>
    </div>
  );
};

function App() {
  const setCurrentAuthUser = useSetAtom(currentAuthUserAtom);
  const currentAuthUser = useAtomValue(currentAuthUserAtom);
  const setAuthUserGroup = useSetAtom(authUserGroupAtom);
  const isLoggedIn = useAtomValue(isLoggedInAtom);
  const setAppConfig = useSetAtom(appConfigAtom);

  const getAuth = useCallback(async () => {
    setCurrentAuthUser(null);
    try {
      const currentUser = await getCurrentUser();
      setCurrentAuthUser(currentUser);
    } catch (e) {
      console.log(e);
    }
  }, [setCurrentAuthUser]);

  useEffect(() => {
    getAuth();
  }, [getAuth]);

  const handleFetchAuthSession = useCallback(async () => {
    try {
      if (currentAuthUser === null) {
        return;
      }
      const authSession = await fetchAuthSession();
      const authUserGroup =
        authSession.tokens?.accessToken.payload["cognito:groups"] ?? null;
      setAuthUserGroup(authUserGroup as string[]);
    } catch (e) {
      console.error(e);
    }
  }, [currentAuthUser, setAuthUserGroup]);

  useEffect(() => {
    handleFetchAuthSession();
  }, [handleFetchAuthSession]);

  const { data: appConfig } = useFetcherWithSwr({
    apiName: "event_manager",
    serviceName: "default",
    operationId: "getConfig",
    requestParameters: {},
  });

  useEffect(() => {
    if (appConfig) {
      setAppConfig(appConfig);
    }
  }, [appConfig, setAppConfig]);

  if (!appConfig) {
    return <Loading />;
  }

  if (appConfig.flags.is_service_unavailable) {
    return (
      <div className="mx-auto max-w-md p-4">
        <Helmet>
          <title>{appConfig.displays.event_short_name}</title>
        </Helmet>
        <div className="grid gap-2 rounded-sm border p-4">
          <img src="/logo.png" alt="logo" className="mb-2 block w-52" />
          <h1 className="text-xl">サービス停止中</h1>
          <p>{appConfig.displays.service_unavailable_message}</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Helmet
        titleTemplate={`%s - ${appConfig?.displays.event_short_name ?? ""}`}
        defaultTitle={appConfig?.displays.event_short_name ?? ""}
      >
        <title>{appConfig?.displays.event_short_name ?? ""}</title>
      </Helmet>
      {isLoggedIn === undefined ? (
        <Loading />
      ) : (
        <RouterProvider router={isLoggedIn ? authRouter : unauthRouter} />
      )}
      <Toaster />
    </div>
  );
}

export default App;
