import { useFetcherWithSwr, useSender } from "@/api";
import { appConfigAtom } from "@/atoms";
import { Alert } from "@/components/ui/alert";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Separator } from "@/components/ui/separator";
import { ConfirmedReservation } from "@/generatedApi/eventManagerApi";
import { useTimeTableMap } from "@/hooks";
import { AutonomousEventSessionSummaryCard } from "@/pages/EventSessionListPage/EventSessionSummaryCard/AutonomousEventSessionSummaryCard";
import { useAtomValue } from "jotai";
import { PlusIcon, Trash2Icon } from "lucide-react";
import { Fragment, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import { SessionCheckInSegment } from "./SessionCheckInSegment";
type UserConfirmedReservationListProps = {
  userId: string;
};
export const UserConfirmedReservationList = ({
  userId,
}: UserConfirmedReservationListProps) => {
  const {
    data: confirmedReservationList,
    isLoading: isGetConfirmedReservationListPending,
  } = useFetcherWithSwr({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "getConfirmedReservationListByUserId",
    requestParameters: { userId },
  });

  const timeSlotConfirmedReservationList = useMemo(() => {
    const res: { [timeSlot: number]: ConfirmedReservation[] } = {};
    confirmedReservationList?.forEach((r) => {
      if (!res[r.time_slot]) {
        res[r.time_slot] = [];
      }
      res[r.time_slot].push(r);
    });
    return res;
  }, [confirmedReservationList]);

  ///

  const [
    isAllConfirmedReservationDeleting,
    setIsAllConfirmedReservationDeleting,
  ] = useState(false);

  const { sender: deleteConfirmedReservation } = useSender({
    apiName: "event_manager",
    serviceName: "users",
    operationId: "deleteConfirmedReservation",
  });

  const handleDeleteAllConfirmedReservation = useCallback(async () => {
    if (!confirmedReservationList || confirmedReservationList.length === 0) {
      toast.error("削除対象がありません");
      return;
    }

    setIsAllConfirmedReservationDeleting(true);
    await Promise.all(
      confirmedReservationList.map((r) =>
        deleteConfirmedReservation({
          reservationId: r.reservation_id,
          userId,
        })
      )
    );
    setIsAllConfirmedReservationDeleting(false);
    window.location.reload();
  }, [deleteConfirmedReservation, confirmedReservationList, userId]);

  const hasReservation = useMemo(
    () => confirmedReservationList?.length !== 0,
    [confirmedReservationList]
  );

  const timeTableMap = useTimeTableMap();

  const appConfig = useAtomValue(appConfigAtom);
  if (!appConfig) return <>読み込み中...</>;

  return (
    <>
      {!appConfig.flags.is_confirmed_reservation_updatable && (
        <Alert>
          セッションの登録期間外です。変更や取り消しなどは事務局にお問い合わせください。
        </Alert>
      )}
      {isGetConfirmedReservationListPending ? (
        "読み込み中..."
      ) : (
        <div className="grid gap-4">
          {[1, 2, 3, 4, 5].map((timeSlot, index) => (
            <Fragment key={timeSlot}>
              {index !== 0 && <Separator />}
              <div className="grid gap-2">
                <div className="mb-4 flex gap-0.5">
                  <div className="bg-slate-600 px-2 text-white">
                    {timeSlot}時間目
                  </div>
                  <div className="rounded-br-sm rounded-tr-sm bg-slate-600 px-2 text-white">
                    {timeTableMap[timeSlot]}
                  </div>
                </div>
                <div className="grid gap-2">
                  {(!timeSlotConfirmedReservationList[timeSlot] ||
                    timeSlotConfirmedReservationList[timeSlot]?.length === 0) &&
                    "登録がありません。"}
                  {timeSlotConfirmedReservationList[timeSlot]?.map(
                    (confirmedReservation) => (
                      <div
                        key={confirmedReservation.reservation_id}
                        className="grid gap-4"
                      >
                        <div className="-mx-3">
                          <AutonomousEventSessionSummaryCard
                            eventSessionId={
                              confirmedReservation.event_session_id
                            }
                            confirmedReservationList={confirmedReservationList}
                          />
                        </div>
                        <SessionCheckInSegment
                          reservationId={confirmedReservation.reservation_id}
                          userId={userId}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      )}
      {appConfig.flags.is_confirmed_reservation_updatable && (
        <>
          <Link
            to={`/event_sessions`}
            className={buttonVariants({ size: "sm" })}
          >
            <PlusIcon className="mr-2 h-4 w-4" />
            セッション一覧から追加登録
          </Link>
          <Dialog>
            <DialogTrigger asChild>
              <div>
                <Button
                  variant="outline"
                  size="sm"
                  disabled={
                    isAllConfirmedReservationDeleting || !hasReservation
                  }
                >
                  <Trash2Icon className="mr-1 h-4 w-4" />
                  確定済みの参加登録を一括削除
                </Button>
              </div>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
              <DialogHeader>
                <DialogTitle>登録を一括削除する</DialogTitle>
                <DialogDescription>
                  {confirmedReservationList?.length ?? 0}
                  件の登録を一括削除します。
                  登録しなおす場合、定員により参加できない可能性があります。
                </DialogDescription>
              </DialogHeader>

              <DialogFooter>
                <Button
                  variant="destructive"
                  disabled={isAllConfirmedReservationDeleting}
                  onClick={handleDeleteAllConfirmedReservation}
                >
                  {isAllConfirmedReservationDeleting
                    ? "処理中..."
                    : "一括削除する"}
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
};
