import { buttonVariants } from "@/components/ui/button";
import { ChevronLeft } from "lucide-react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export const NotFoundPage = () => {
  return (
    <div className="gap mx-auto max-w-md space-y-4">
      <Helmet>
        <title>404 Not Found</title>
      </Helmet>
      <div>
        <h1 className="text-xl">404 Not Found</h1>
        <p>ページが見つかりませんでした。</p>
      </div>
      <Link to="/" className={buttonVariants({ variant: "secondary" })}>
        <ChevronLeft className="mr-1 h-4 w-4" />
        トップページへ戻る
      </Link>
      <div>
        <img src="/404.svg" alt="404 Not Found" className="mx-auto w-1/4" />
      </div>
    </div>
  );
};
