import {
  EventSessionEligible,
  EventSessionType,
  ParticipantCategory,
} from "./generatedApi/eventManagerApi";

export const eventSessionTypeMap: { [eventSessionType: string]: string } = {
  [EventSessionType.LECTURE]: "講義",
  [EventSessionType.WORKSHOP]: "体験教室",
  [EventSessionType.TOUR]: "見学",
  [EventSessionType.OTHER]: "その他",
};

export const participantCategoryMap: { [category: string]: string } = {
  [ParticipantCategory.ELEMENTARY_SCHOOL_LOWER]: "小学校低学年",
  [ParticipantCategory.ELEMENTARY_SCHOOL_MIDDLE]: "小学校中学年",
  [ParticipantCategory.ELEMENTARY_SCHOOL_UPPER]: "小学校高学年",
  [ParticipantCategory.JUNIOR_HIGH_SCHOOL]: "中学生",
  [ParticipantCategory.HIGH_SCHOOL]: "高校生",
  [ParticipantCategory.HIGHER_EDUCATION]: "大学・高専・専門学校",
  [ParticipantCategory.ADULT]: "一般(保護者を含む)",
};

export const genderMap: { [label: string]: string } = {
  male: "男性",
  female: "女性",
  na: "回答しない",
};

export const eventSessionEligibleMap: { [eligible: string]: string } = {
  [EventSessionEligible.NOT_ALLOWED]: "申し込み不可",
  [EventSessionEligible.ALLOWED]: "申し込み可能",
  [EventSessionEligible.ALLOWED_WITH_ELEMENTARY_SCHOOL]:
    "グループに小学生が含まれる時可能",
  [EventSessionEligible.ALLOWED_WITH_ELEMENTARY_OR_JUNIOR_HIGH_SCHOOL]:
    "グループに小中学生が含まれる時可能",
};
