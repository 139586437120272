import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { eventSessionTypeMap, } from "@/constants";
import { EventSession } from "@/generatedApi/eventManagerApi";
import { useTimeTableMap } from "@/hooks";

type EventSessionSingleDetailCardProps = {
  eventSession: EventSession;
};
export const EventSessionSingleDetailCard = ({
  eventSession,
}: EventSessionSingleDetailCardProps) => {
  const timeTableMap = useTimeTableMap();
  return (
    <Card>
      <CardHeader>
        <CardTitle>セッション詳細</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid gap-3">
          <div>
            <div className="text-sm text-muted-foreground">コード</div>
            <div className="">{eventSession.event_session_id}</div>
          </div>
          <div>
            <div className="text-sm text-muted-foreground">種別</div>
            <div className="">
              {eventSessionTypeMap[eventSession.event_session_type]}
            </div>
          </div>
          <div>
            <div className="text-sm text-muted-foreground">開催時間帯</div>
            <div className="">
              S{eventSession.time_slot}: {timeTableMap[eventSession.time_slot]}
            </div>
          </div>
          <div>
            <div className="text-sm text-muted-foreground">講師</div>
            <div className="">{eventSession.lecturer_name ?? "-"}</div>
          </div>
          <div>
            <div className="text-sm text-muted-foreground">講師所属</div>
            <div className="">{eventSession.lecturer_affiliation ?? "-"}</div>
          </div>
          <div>
            <div className="text-sm text-muted-foreground">定員</div>
            <div className="">{eventSession.max_capacity ?? "-"}</div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
