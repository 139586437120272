import { useSender } from "@/api";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { participantCategoryMap } from "@/constants";
import { SecureUserAccount } from "@/generatedApi/eventManagerApi";
import { useSecureEmail } from "@/hooks";

import { ChevronRight } from "lucide-react";
import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";

type UserAccountCardProps = {
  userId: string;
  secureUserAccount?: SecureUserAccount;
};
export const UserAccountCard = ({
  userId,
  secureUserAccount,
}: UserAccountCardProps) => {
  const navigate = useNavigate();
  const secureEmail = useSecureEmail();
  const { sender: createUserAccount, isLoading: isCreateUserAccountPending } =
    useSender({
      apiName: "event_manager",
      serviceName: "users",
      operationId: "createUserAccount",
    });

  const handleCreateUserAccount = useCallback(async () => {
    if (!secureUserAccount) {
      if (secureEmail === undefined) {
        toast.error("メールアドレスが取得できませんでした。");
        return;
      }
      await createUserAccount({ userId, secureEmail });
    }
    navigate(`/users/${userId}`);
  }, [secureEmail, secureUserAccount, createUserAccount, userId, navigate]);

  const completeUserAccount =
    secureUserAccount?.secure_email &&
    secureUserAccount?.applicant &&
    secureUserAccount?.participant_list &&
    secureUserAccount?.emergency_contact;

  const participantList = secureUserAccount?.participant_list;

  return (
    <>
      {completeUserAccount ? (
        <Card>
          <CardHeader>
            <CardTitle>参加者情報</CardTitle>
          </CardHeader>
          <CardContent>
            {participantList!.items.length === 0 && (
              <p>参加者が登録されていません。</p>
            )}

            <ul className="grid gap-2">
              {participantList!.items.map((participant, index) => (
                <li className="flex items-start justify-between" key={index}>
                  <div className="">{participant.name}</div>
                  <div className="flex flex-col items-end gap-1">
                    <span className="text-muted-foreground">
                      {participantCategoryMap[participant.participant_category]}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </CardContent>
          <CardFooter>
            <Link to={`/users/${userId}`} className={buttonVariants({})}>
              参加者情報の詳細
              <ChevronRight className="ml-1 h-4 w-4" />
            </Link>
          </CardFooter>
        </Card>
      ) : (
        <Card>
          <CardHeader>
            <CardTitle>
              参加者情報の登録を
              <br />
              完了してください
            </CardTitle>
            <CardDescription>
              すべて完了するとセッションの参加希望登録が利用できます。
            </CardDescription>
          </CardHeader>
          <CardFooter>
            <Button
              onClick={() => handleCreateUserAccount()}
              disabled={isCreateUserAccountPending}
            >
              {isCreateUserAccountPending ? "処理中" : "参加者情報を登録"}
              <ChevronRight className="ml-1 h-4 w-4" />
            </Button>
          </CardFooter>
        </Card>
      )}
    </>
  );
};
